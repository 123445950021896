import React from "react";
import Moment from "react-moment";
import AuditLog from "../../elements/AuditLog";
import Workbook from "../../elements/Workbook";
import ReactStars from "react-stars";
import ReactTable from "react-table";
import "react-table/react-table.css";

import classnames from "classnames";

import Dialog from "material-ui/Dialog";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import IconButton from "material-ui/IconButton";
import DeleteIcon from "material-ui/svg-icons/action/delete";

import MaidhawkAPI from "../../../api";
import {
  spStatusDescription,
  spOnboardNextSteps,
  reactMomentFormatDate,
  reactMomentFormatDateTime,
} from "../../../constants";

export default class ServiceProviders extends React.Component {
  static propTypes = {};
  static defaultProps = {};
  state = {
    maids: [],
    maidId: false,
    starsFilter: 0,
  };

  componentDidMount() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("GET", "/v1/maids").then((maids) =>
      this.setState({ maids, maidId: false })
    );
  }

  componentDidUpdate() {
    document.getElementById("processSpinner").style.visibility = "hidden";
  }

  render() {
    return (
      <div className={classnames("About", this.props.className)}>
        <div style={{ textAlign: "right", padding: "20px" }}>
          <Workbook
            filename={"serviceProviders" + new Date().toString() + ".xlsx"}
            element={<RaisedButton label="export to excel" />}
          >
            <Workbook.Sheet data={this.state.maids} name="Sheet A">
              <Workbook.Column label="service provider name" value="name" />
              <Workbook.Column label="last name" value="lastName" />
              <Workbook.Column label="email" value="email" />
              <Workbook.Column
                label="mobile number"
                value={(maid) => Intl.USTelephone(maid.mobileNumber)}
              />
              <Workbook.Column
                label="number of cleanings"
                value={(maid) => maid.cleanings.length}
              />
              <Workbook.Column
                label="last cleaning"
                value={(maid) =>
                  maid.cleanings.length ? maid.cleanings[0].dateArrived : null
                }
              />
              <Workbook.Column label="onLine" value="onLine" />
              <Workbook.Column label="status" value="status" />
            </Workbook.Sheet>
          </Workbook>
        </div>
        <ReactTable
          className="-striped -highlight"
          data={this.state.maids}
          defaultPageSize={10}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .includes(filter.value.toLowerCase())
          }
          columns={[
            {
              Header: "service provider name",
              accessor: "name",
            },
            {
              Header: "last name",
              accessor: "lastName",
            },
            {
              Header: "email",
              accessor: "email",
            },
            {
              id: "mobileNumber",
              Header: "mobile number",
              accessor: (maid) => Intl.USTelephone(maid.mobileNumber),
            },
            {
              id: "countCleanings",
              Header: "cleanings",
              accessor: (maid) => maid.cleanings.length,
              filterMethod: (filter, maid) =>
                maid[filter.id] === parseInt(filter.value),
            },
            {
              id: "lastCleaning",
              Header: "last cleaning",
              accessor: (maid) =>
                maid.cleanings.length > 0
                  ? new Date(maid.cleanings[0].dateArrived)
                  : undefined,
              Cell: (cell) =>
                cell.value ? (
                  <Moment
                    format={reactMomentFormatDateTime}
                    date={cell.value}
                  />
                ) : (
                  ""
                ),
              filterable: false,
            },
            {
              id: "isOnline",
              Header: "online",
              accessor: (maid) => (maid.isOnline ? "online" : ""),
              filterMethod: (filter, maid) =>
                filter.value === "all" ||
                filter.value === (maid[filter.id] ? "online" : "offline"),
              Filter: ({ filter, onChange }) => (
                <select
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  onChange={(event) => onChange(event.target.value)}
                >
                  <option value="all">show all</option>
                  <option value="online">online</option>
                  <option value="offline">offline</option>
                </select>
              ),
            },
            {
              id: "status",
              Header: "status",
              accessor: (maid) => maid.status.toLowerCase(),
              filterMethod: (filter, maid) =>
                filter.value === "all" ||
                filter.value === maid.status.toUpperCase(),
              Filter: ({ filter, onChange }) => (
                <select
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  onChange={(event) => onChange(event.target.value)}
                >
                  <option value="all">show all</option>
                  <option value="REGISTERED">registered</option>
                  <option value="SENTTOBACKGROUNDCHECK">
                    {" "}
                    pending background check{" "}
                  </option>
                  <option value="BACKGROUNDCHECKFAILED">
                    {" "}
                    failed background check{" "}
                  </option>
                  <option value="REJECTED">rejected</option>
                  <option value="SKIPBACKGROUNDCHECK">
                    {" "}
                    skipped background check{" "}
                  </option>
                  <option value="BACKGROUNDCHECKPASSED">
                    {" "}
                    passed background check{" "}
                  </option>
                  <option value="PENDINGSTRIPE">pending stripe</option>
                  <option value="APPROVED">approved</option>
                  <option value="ACTIVE">active</option>
                  <option value="INACTIVE">inactive</option>
                </select>
              ),
            },
          ]}
          getTrProps={(_, reactTableRow) => ({
            onClick: () =>
              this.setState({ maidId: reactTableRow.original._id }),
          })}
        />
        {this.state.maidId && (
          <ServiceProviderDialog
            maidId={this.state.maidId}
            close={() => this.componentDidMount()}
          />
        )}
      </div>
    );
  }
}

class ServiceProviderDialog extends React.Component {
  static propTypes = {};
  static defaultProps = {};
  state = {
    maid: { cleanings: [], audit: [] },
    addNoteDialogOpen: false,
    newNote: undefined,
    transferDialogOpen: false,
    transferAmount: undefined,
  };

  componentDidMount() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("GET", `/v1/maid/${this.props.maidId}`).then((maid) =>
      MaidhawkAPI.call("GET", `/v1/user/${maid._id}/audit`).then((audit) => {
        maid.audit = audit;
        this.setState({ maid });
      })
    );
  }

  addProviderNote() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("POST", `/v1/user/${this.props.maidId}/notes`, {
      message: this.state.newNote,
    }).then((note) => {
      note.changedBy = { email: "you" };
      note.eventDate = new Date();

      const { maid } = this.state;
      maid.audit.splice(0, 0, note);
      this.setState({ maid, addNoteDialogOpen: false });
    });
  }

  setMaidStatus(status) {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("PUT", `/v1/maid/${this.props.maidId}/status`, {
      status,
    }).then((response) => response.success && this.componentDidMount());
  }

  processTransfer() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("POST", `/v1/maid/${this.props.maidId}/payments`, {
      amount: this.state.transferAmount,
    }).then(() => this.setState({ transferDialogOpen: false }));
  }

  componentDidUpdate() {
    document.getElementById("processSpinner").style.visibility = "hidden";
  }

  render() {
    let maidNextSteps = spOnboardNextSteps[this.state.maid.status] || [];

    return (
      <Dialog
        contentStyle={commonStyles.dialogStyle}
        actionsContainerStyle={commonStyles.actionsContainerStyle}
        actions={[
          <FlatButton
            style={actionButtonStyle}
            labelStyle={commonStyles.headerButtonLabel}
            label="Ok"
            onClick={() => this.props.close()}
          />,
        ]}
        autoScrollBodyContent={true}
        modal={true}
        fullScreen={true}
        open={true}
      >
        <div className="row" style={commonStyles.headerDialogStyle}>
          <div className="col" style={commonStyles.headerButtonContainer}>
            <FlatButton
              label="Reject SP"
              labelPosition="before"
              labelStyle={{
                ...commonStyles.headerButtonLabel,
                opacity: maidNextSteps.includes("REJECTED") ? 1 : 0.3,
              }}
              style={commonStyles.headerButton}
              fullWidth={true}
              containerElement="label"
              disabled={!maidNextSteps.includes("REJECTED")}
              onClick={() => this.setMaidStatus("REJECTED")}
            />
          </div>
          <div className="col" style={commonStyles.headerButtonContainer}>
            <FlatButton
              label="Skip BG"
              labelPosition="before"
              labelStyle={{
                ...commonStyles.headerButtonLabel,
                opacity: maidNextSteps.includes("SKIPBACKGROUNDCHECK")
                  ? 1
                  : 0.3,
              }}
              style={commonStyles.headerButton}
              fullWidth={true}
              containerElement="label"
              disabled={!maidNextSteps.includes("SKIPBACKGROUNDCHECK")}
              onClick={() => this.setMaidStatus("SKIPBACKGROUNDCHECK")}
            />
          </div>
          <div className="col" style={commonStyles.headerButtonContainer}>
            <FlatButton
              label="Send BG"
              labelPosition="before"
              labelStyle={{
                ...commonStyles.headerButtonLabel,
                opacity: maidNextSteps.includes("SENTTOBACKGROUNDCHECK")
                  ? 1
                  : 0.3,
              }}
              style={commonStyles.headerButton}
              fullWidth={true}
              containerElement="label"
              disabled={!maidNextSteps.includes("SENTTOBACKGROUNDCHECK")}
              onClick={() => this.setMaidStatus("SENTTOBACKGROUNDCHECK")}
            />
          </div>
          <div className="col" style={commonStyles.headerButtonContainer}>
            <FlatButton
              label="BG failed"
              labelPosition="before"
              labelStyle={{
                ...commonStyles.headerButtonLabel,
                opacity: maidNextSteps.includes("BACKGROUNDCHECKFAILED")
                  ? 1
                  : 0.3,
              }}
              style={commonStyles.headerButton}
              fullWidth={true}
              containerElement="label"
              disabled={!maidNextSteps.includes("BACKGROUNDCHECKFAILED")}
              onClick={() => this.setMaidStatus("BACKGROUNDCHECKFAILED")}
            />
          </div>
          <div className="col" style={commonStyles.headerButtonContainer}>
            <FlatButton
              label="BG accepted"
              labelPosition="before"
              labelStyle={{
                ...commonStyles.headerButtonLabel,
                opacity: maidNextSteps.includes("BACKGROUNDCHECKPASSED")
                  ? 1
                  : 0.3,
              }}
              style={commonStyles.headerButton}
              fullWidth={true}
              containerElement="label"
              disabled={!maidNextSteps.includes("BACKGROUNDCHECKPASSED")}
              onClick={() => this.setMaidStatus("BACKGROUNDCHECKPASSED")}
            />
          </div>
          <div className="col" style={commonStyles.headerButtonContainer}>
            <FlatButton
              label="Approve SP"
              labelPosition="before"
              labelStyle={{
                ...commonStyles.headerButtonLabel,
                opacity: maidNextSteps.includes("APPROVED") ? 1 : 0.3,
              }}
              style={commonStyles.headerButton}
              fullWidth={true}
              containerElement="label"
              disabled={!maidNextSteps.includes("APPROVED")}
              onClick={() => this.setMaidStatus("PENDINGSTRIPE")}
            />
          </div>
          <div className="col" style={commonStyles.headerButtonContainer}>
            <FlatButton
              label="Deposit Maid"
              labelPosition="before"
              labelStyle={{
                ...commonStyles.headerButtonLabel,
                opacity: this.state.maid.stripeAccount ? 1 : 0.3,
              }}
              style={commonStyles.headerButton}
              fullWidth={true}
              containerElement="label"
              onClick={() =>
                this.state.maid.stripeAccount &&
                this.setState({ transferDialogOpen: true, transferAmount: 0 })
              }
            />
          </div>
          <div className="col" style={commonStyles.headerButtonContainer}>
            <FlatButton
              label={
                this.state.maid.status === "INACTIVE"
                  ? "ACTIVATE"
                  : "DEACTIVATE"
              }
              labelPosition="before"
              labelStyle={commonStyles.headerButtonLabel}
              style={commonStyles.headerButton}
              fullWidth={true}
              containerElement="label"
              onClick={() =>
                this.setMaidStatus(
                  this.state.maid.status === "INACTIVE" ? "ACTIVE" : "INACTIVE"
                )
              }
            />
          </div>
        </div>
        <div className="row" style={commonStyles.groupBoxContainer}>
          <div className="col-md-8" style={{ padding: "0px" }}>
            <div className="row" style={leftGroupBoxTopStyle}>
              <div className="col-md-12">
                <div className="row ">
                  <span className="blackTitle">service provider</span>
                </div>
                <div className="row ">
                  <div className="col-md-3">
                    <span className="formLabel">Name</span>
                  </div>
                  <div className="col-md-9">
                    <span>
                      {this.state.maid.name} {this.state.maid.lastName}
                    </span>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-3">
                    <span className="formLabel">Email</span>
                  </div>
                  <div className="col-md-9">
                    <span>{this.state.maid.email}</span>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-3">
                    <span className="formLabel">Cell Phone</span>
                  </div>
                  <div className="col-md-9">
                    <span>
                      {Intl.USTelephone(this.state.maid.mobileNumber)}
                    </span>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-3">
                    <span className="formLabel">Rating</span>
                  </div>
                  <div className="col-md-9">
                    <ReactStars
                      className="thinStarsContainer"
                      count={5}
                      value={this.state.maid.maidRating}
                      edit={false}
                      size={18}
                      color2={"#ffd700"}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <span className="formLabel">Past cleanings</span>
                  </div>
                  <div className="col-md-9">
                    <span>{this.state.maid.cleanings.length}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <span className="formLabel">Provider since</span>
                  </div>
                  <div className="col-md-9">
                    <span>
                      <Moment
                        format={reactMomentFormatDate}
                        date={this.state.maid.dateRegistered}
                      />
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <span className="formLabel">Status</span>
                  </div>
                  <div className="col-md-9">
                    <span>
                      {spStatusDescription[this.state.maid.status] || "unknown"}
                      {this.state.maid.online && "ONLINE"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={leftGroupBoxStyle}>
              <div className="col-md-12">
                <div className="row formPair">
                  <span className="blackTitle">Past Cleanings</span>
                </div>
                <div className="row formPair">
                  <div className="col-md-12">
                    <ReactTable
                      className="-striped -highlight"
                      style={commonStyles.tableStyle}
                      data={this.state.maid.cleanings}
                      pageSize={10}
                      defaultPageSize={10}
                      showPageSizeOptions={false}
                      autoScrollBodyContent={true}
                      filterable
                      defaultFilterMethod={(filter, row) =>
                        String(row[filter.id])
                          .toLowerCase()
                          .includes(filter.value.toLowerCase())
                      }
                      columns={[
                        {
                          Header: "order number",
                          headerStyle: commonStyles.tableHeaderStyle,
                          accessor: "serialId",
                        },
                        {
                          Header: "order date",
                          headerStyle: commonStyles.tableHeaderStyle,
                          accessor: "dateCreated",
                          Cell: (cell) =>
                            cell.value ? (
                              <Moment
                                format={reactMomentFormatDateTime}
                                date={cell.value}
                              />
                            ) : (
                              ""
                            ),
                          filterable: false,
                        },
                        {
                          id: "amount",
                          Header: "amount",
                          headerStyle: commonStyles.tableHeaderStyle,
                          style: { textAlign: "right" },
                          accessor: (cleaning) => Intl.USMoney(cleaning.amount),
                        },
                        {
                          Header: "customer",
                          headerStyle: commonStyles.tableHeaderStyle,
                          accessor: "createdBy.email",
                        },
                        {
                          Header: "address",
                          headerStyle: commonStyles.tableHeaderStyle,
                          id: "address",
                          accessor: ({ street, unit, city, state, zip }) =>
                            Intl.USAddress(street, unit, city, state, zip),
                        },
                        {
                          Header: "order details",
                          headerStyle: commonStyles.tableHeaderStyle,
                          id: "details",
                          accessor: (row) => {
                            const { bedrooms, bathrooms } = row.details[0];
                            return Intl.pricing`${bedrooms} ${bathrooms}`; // tagged template
                          },
                        },
                        {
                          Header: "customer rating",
                          headerStyle: commonStyles.tableHeaderStyle,
                          width: 200,
                          accessor: "customerRating",
                          filterMethod: (filter, cleaning) =>
                            filter.value === 0 ||
                            cleaning[filter.id] === filter.value,
                          Filter: ({ onChange }) => {
                            return (
                              <div className="row">
                                <div className="col-9">
                                  <ReactStars
                                    className="centeredStarsFilter"
                                    value={this.state.starsFilter}
                                    half={false}
                                    count={5}
                                    size={18}
                                    color2={"#ffd700"}
                                    onChange={(value) => {
                                      this.setState({
                                        starsFilter: value,
                                      });
                                      onChange(value);
                                    }}
                                  />
                                </div>
                                <div
                                  className="col-2"
                                  style={{ padding: "0px", margin: "0px" }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      this.setState({
                                        starsFilter: 0,
                                      });
                                      onChange(0);
                                    }}
                                    style={{
                                      padding: "0px",
                                      margin: "0px",
                                    }}
                                  >
                                    <DeleteIcon color={"#d7d7d7"} />
                                  </IconButton>
                                </div>
                              </div>
                            );
                          },
                        },
                        {
                          Header: "payment",
                          headerStyle: commonStyles.tableHeaderStyle,
                          accessor: "maidAmount",
                        },
                        {
                          Header: "fee",
                          headerStyle: commonStyles.tableHeaderStyle,
                          accessor: "maidhawkFee",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4" style={{ padding: "0px 0px 0px 10px" }}>
            <div className="row" style={rightGroupBoxStyle}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 col-6">
                    <span className="blackSubTitle">Notes</span>
                  </div>
                  <div
                    className="offset-md-2 col-md-4 col-6"
                    style={{ textAlign: "right" }}
                  >
                    <FlatButton
                      label="Add Note"
                      labelStyle={commonStyles.headerButtonLabel}
                      style={actionButtonStyle}
                      onClick={() =>
                        this.setState({
                          addNoteDialogOpen: true,
                          newNote: "",
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-12"
                    style={{ height: "500px", overflowY: "scroll" }}
                  >
                    <AuditLog log={this.state.maid.audit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          contentStyle={{ width: "550px" }}
          actionsContainerStyle={commonStyles.actionsContainerStyle}
          actions={[
            <FlatButton
              label="save"
              labelStyle={commonStyles.headerButtonLabel}
              style={actionButtonStyle}
              onClick={() => this.addProviderNote()}
            />,
            <FlatButton
              label="Cancel"
              labelStyle={commonStyles.headerButtonLabel}
              style={actionButtonStyle}
              onClick={() => this.setState({ addNoteDialogOpen: false })}
            />,
          ]}
          repositionOnUpdate={false}
          modal={true}
          open={this.state.addNoteDialogOpen}
        >
          <div className="row" style={{ padding: "20px 15px" }}>
            <div className="col-md-12">
              <div className="row  formPair">
                <div className="col-md-12">
                  <TextField
                    floatingLabelStyle={textFieldFloatingLabelStyle}
                    style={textFieldStyle}
                    fullWidth={true}
                    multiLine={true}
                    rows={3}
                    rowsMax={4}
                    floatingLabelText="add service provider note"
                    value={this.state.newNote}
                    onChange={(e) => this.setState({ newNote: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          width="300px"
          actionsContainerStyle={commonStyles.actionsContainerStyle}
          actions={[
            <FlatButton
              style={actionButtonStyle}
              labelStyle={commonStyles.headerButtonLabel}
              label="Deposit"
              onClick={() => this.processTransfer()}
            />,
            <FlatButton
              style={actionButtonStyle}
              labelStyle={commonStyles.headerButtonLabel}
              label="Cancel"
              onClick={() => this.setState({ transferDialogOpen: false })}
            />,
          ]}
          repositionOnUpdate={false}
          modal={true}
          open={this.state.transferDialogOpen}
        >
          <div className="row" style={{ padding: "20px 15px" }}>
            <div className="col-md-12">
              <div className="row  formPair">
                <div className="col-md-12">
                  <TextField
                    floatingLabelStyle={textFieldFloatingLabelStyle}
                    style={textFieldStyle}
                    fullWidth={true}
                    floatingLabelText="Amount of money to transfer to maid"
                    value={this.state.transferAmount}
                    onChange={(e) =>
                      this.setState({ transferAmount: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </Dialog>
    );
  }
}

const commonStyles = {
  actionsContainerStyle: {
    padding: "10px 35px 10px 35px",
  },
  groupBoxContainer: {
    padding: "10px 0px 10px 0px",
  },
  groupBox: {
    backgroundColor: "white",
    border: "1px solid gray",
    borderRadius: "10px",
    padding: "5px 0px 0px 0px",
    color: "gray",
  },
  headerButtonContainer: {
    padding: "0px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  headerButtonLabel: {
    width: "100%",
    margin: "0px",
    padding: "0px",
    textTransform: "lowercase",
    fontSize: "10pt",
    lineHeight: "30px",
  },
  headerButton: {
    verticalAlign: "middle",
    color: "black",
    backgroundColor: "white",
    border: "1px solid gray",
    width: "100%",
    margin: "0px",
    lineHeight: "30px",
    height: "32px",
  },
  uploadInput: {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100%",
    opacity: 0,
  },
  dialogStyle: {
    width: "85%",
    maxWidth: "none",
    padding: "0px",
  },
  headerDialogStyle: {
    background: "gray",
    color: "black",
    padding: "10px",
    height: "100%",
    alignContent: "center",
    border: "1px solid gray",
  },
  tableStyle: {
    width: "100%",
    height: "300px",
  },
  tableHeaderStyle: {
    fontSize: "12pt",
  },
};
const actionButtonStyle = {
  ...commonStyles.headerButton,
  width: "auto",
};
const leftGroupBoxTopStyle = {
  ...commonStyles.groupBox,
  marginRight: "5px",
  marginBottom: "20px",
};
const leftGroupBoxStyle = {
  ...commonStyles.groupBox,
  marginRight: "5px",
};
const rightGroupBoxStyle = {
  ...commonStyles.groupBox,
  height: "100%",
};
const textFieldFloatingLabelStyle = {
  color: "gray",
  fontSize: "16pt !important",
};
const textFieldStyle = {
  backgroundColor: "white",
};
