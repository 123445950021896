import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import MaidhawkAPI from "../../api";

import Header from "./Header";
import Footer from "./Footer";

import Login from "../Login";
import NotFound from "../NotFound";
import StripeExpress from "../StripeExpress";

import Home from "../views/Home";
import Maids from "../views/Maids";
import About from "../views/About";
import Faqs from "../views/Faqs";
import TermsOfService from "../views/TermsOfService";
import PrivacyPolicy from "../views/PrivacyPolicy";
import Request from "../views/Request";

import Admin from "../views/admin/Home";
import Customers from "../views/admin/Customers";
import ServiceProviders from "../views/admin/ServiceProviders";
import Orders from "../views/admin/Orders";
import Coupons from "../views/admin/Coupons";
import Settings from "../views/admin/Settings";

const AdminRoute = ({ component: Protected, ...otherProps }) => (
  <Route
    {...otherProps}
    render={(props) =>
      MaidhawkAPI.authenticated() === true ? (
        <Protected {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/Login",
            state: { from: props.location, roles: ["ADMIN"] },
          }}
          onEnter
        />
      )
    }
  />
);

export default class Body extends React.Component {
  render() {
    document.getElementById("processSpinner").style.visibility = "hidden";

    return (
      <BrowserRouter>
        <React.Fragment>
          <Header />
          <Route path="/Login" component={Login} />
          <div
            id="main"
            style={{ fontFamily: "Courier New", fontSize: "18px" }}
          >
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/maids" component={Maids} />
              <Route
                path="/termsofservice"
                render={(props) => (
                  <TermsOfService api={MaidhawkAPI} {...props} />
                )}
              />
              <Route
                path="/privacypolicy"
                render={(props) => (
                  <PrivacyPolicy api={MaidhawkAPI} {...props} />
                )}
              />
              <Route
                path="/faqs"
                render={(props) => <Faqs api={MaidhawkAPI} {...props} />}
              />
              <Route path="/notfound" component={NotFound} />
              <Route path="/stripe-express" component={StripeExpress} />
              <Route path="/request" component={Request} />
              <AdminRoute path="/Admin" component={Admin} />
              <AdminRoute path="/Orders" component={Orders} />
              <AdminRoute path="/Customers" component={Customers} />
              <AdminRoute
                path="/ServiceProviders"
                component={ServiceProviders}
              />
              <AdminRoute path="/Coupons" component={Coupons} />
              <AdminRoute path="/Settings" component={Settings} />
              <Route component={NotFound} />
            </Switch>
          </div>
          <Footer />
        </React.Fragment>
      </BrowserRouter>
    );
  }
}
