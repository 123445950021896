import React from "react";
import Moment from "react-moment";

import { List, ListItem } from "material-ui/List";
import Divider from "material-ui/Divider";
import Avatar from "material-ui/Avatar";

import { reactMomentFormatDateTime } from "../../constants";

export default ({ log }) => (
  <List>
    {log.reduce((elements, item, index) => {
      if (index > 0) {
        elements.push(<Divider inset={true} key={index - 0.1} />);
      }

      elements.push(<AuditItem item={item} key={index} />);
      return elements;
    }, [])}
  </List>
);

const AuditItem = ({ item }) => {
  let { description, changedBy } = item;

  if (!description && "stripeCharge" in item) {
    const {
      amount,
      source: { brand, last4 },
      metadata: { serialId },
    } = item.stripeCharge;
    description = `Charged $${
      parseInt(amount, 10) / 100.0
    } to ${brand} **${last4}`;
    if (serialId) {
      description.concat(` for request #${serialId}`);
    }
  }

  if (!description && "stripeTransfer" in item) {
    const { amount } = item.stripeTransfer;
    description = `Paid out $${parseInt(amount, 10) / 100.0}`;
  }

  if (!description && "newStatus" in item && "oldStatus" in item) {
    description = `Status changed from ${item.oldStatus} to ${item.newStatus}`;
  }

  const attestation =
    changedBy instanceof Object &&
    "email" in changedBy &&
    `by ${changedBy.email} at `;

  const avatarSrc =
    (changedBy instanceof Object &&
      "avatar" in changedBy &&
      changedBy.avatar) ||
    "resources/profileImage.png";

  return (
    <ListItem
      leftAvatar={
        <Avatar size={48} backgroundColor="transparent" src={avatarSrc} />
      }
      primaryText={
        <span style={{ color: "darkBlack" }}>
          {attestation}
          <Moment format={reactMomentFormatDateTime} date={item.eventDate} />
        </span>
      }
      secondaryText={
        <span style={{ wordWrap: "break-word" }}>{description}</span>
      }
      secondaryTextLines={2}
    />
  );
};
