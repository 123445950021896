export const requestStatusDescription = {
  CREATED: "created",
  ASSIGNED: "assigned",
  ARRIVED: "arrived",
  COMPLETED: "completed",
  UNPAID: "unpaid",
  PAID: "paid",
  CANCELEDBYMAID: "canceled by service provider",
  CANCELEDBYINTERNAL: "canceled by internal",
  CANCELEDBYADMIN: "canceled by administrator",
  CANCELEDBYSYSTEM: "expired by system",
  CANCELEDBYCUSTOMER: "canceled by customer",
};

export const spStatusDescription = {
  REGISTERED: "registered",
  SENTTOBACKGROUNDCHECK: "send BG",
  SKIPBACKGROUNDCHECK: "skip BG",
  BACKGROUNDCHECKPASSED: "BG passed",
  BACKGROUNDCHECKFAILED: "BG failed",
  APPROVED: "approved",
  PENDINGSTRIPE: "pending stripe",
  REJECTED: "rejected",
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const userStatusEnum = [
  // maids:
  "REGISTERED",
  "SENTTOBACKGROUNDCHECK",
  "SKIPBACKGROUNDCHECK",
  "BACKGROUNDCHECKPASSED",
  "BACKGROUNDCHECKFAILED",
  "PENDINGSTRIPE",
  "APPROVED",
  "REJECTED",
  // customers & maids:
  "ACTIVE",
  "INACTIVE",
];

export const customerStatusDescription = {
  ACTIVE: "active",
  INACTIVE: "blocked",
  REGISTERED: "active",
};

export const spOnboardNextSteps = {
  REGISTERED: ["SENTTOBACKGROUNDCHECK", "SKIPBACKGROUNDCHECK", "REJECTED"],
  SENTTOBACKGROUNDCHECK: [
    "BACKGROUNDCHECKFAILED",
    "BACKGROUNDCHECKPASSED",
    "APPROVED",
    "REJECTED",
  ],
  BACKGROUNDCHECKFAILED: [
    "SKIPBACKGROUNDCHECK",
    "SENTTOBACKGROUNDCHECK",
    "REJECTED",
  ],
  BACKGROUNDCHECKPASSED: ["APPROVED", "REJECTED"],
  SKIPBACKGROUNDCHECK: [
    // skipped status needed for audit
    "APPROVED",
    "REJECTED",
  ],
  APPROVED: ["REJECTED"],
  PENDINGSTRIPE: [
    // same as APPROVED, last before ACTIVE
    "REJECTED",
  ],
  ACTIVE: ["INACTIVE", "APPROVED"],
  INACTIVE: ["INACTIVE", "APPROVED"], // once active, can be active again.
  REJECTED: ["SENTTOBACKGROUNDCHECK", "SKIPBACKGROUNDCHECK"],
};

// these next steps indicate actions available to admin rather than normal state transitions
// consider state transition for five available actions
// finish: (!complete) --> (complete) + charge as side-effect
// charge: (*) --> (complete) + charge memo
// refund: (*) --> (complete) + refund memo
export const orderFlowNextSteps = {
  CREATED: ["ASSIGN", "CANCEL"],
  ASSIGNED: ["ASSIGN", "CHARGE", "CANCEL"],
  ARRIVED: ["ASSIGN", "COMPLETE", "CANCEL"], // charge is a side-effect of completion
  UNPAID: ["CHARGE", "COMPLETE", "CANCEL"], // successful charge will achieve completed state
  COMPLETED: ["CHARGE", "REFUND"],
  CANCELEDBYINTERNAL: ["CHARGE", "REFUND"],
  CANCELEDBYADMIN: ["CHARGE", "REFUND"],
  CANCELEDBYSYSTEM: ["CHARGE", "REFUND"],
  CANCELEDBYMAID: ["CHARGE", "REFUND"],
  CANCELEDBYCUSTOMER: ["CHARGE", "REFUND"],
};

export const reactMomentFormatDate = "M/D/YY";
export const reactMomentFormatDateTime = "h:mma M/D/YY";
