import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import originalFaqs from "../../faqs.json";

export default class Faqs extends React.Component {
  static propTypes = {
    api: PropTypes.shape({
      call: PropTypes.func,
    }).isRequired,
  };

  state = {
    faqs: [],
  };

  componentDidMount() {
    this.props.api
      .call("GET", "/v1/settings/faqs")
      .then((settings) => JSON.parse(settings[0].value))
      .catch(() => originalFaqs)
      .then((faqs) => this.setState({ faqs }));
  }

  render() {
    const mainStyle = {
      display: "flex",
      flexDirection: "column",
      padding: "56px 32px",
    };

    const bigImage = (
      <div
        className="row"
        style={{ width: "100%", height: "400px", overflow: "hidden" }}
      >
        <img
          alt="maidhawk department"
          style={{ width: "100%", height: "600px" }}
          src="resources/midtown-night.jpg"
        />
      </div>
    );

    return (
      <React.Fragment>
        <div className="container-fluid" style={{ marginTop: "-150px" }}>
          {bigImage}
          <main id="page" className="container" role="main" style={mainStyle}>
            <div className="row">
              <div className="col--md-12">
                <h2 style={{ marginBottom: "30px" }}>Faqs</h2>
                {this.state.faqs.map(([question, answer], n) => (
                  <React.Fragment key={n}>
                    {n > 0 ? (
                      <p style={{ borderTop: "1px solid gray" }}>
                        <span className="c1" />
                      </p>
                    ) : null}
                    <div style={{ fontSize: "1.2em" }} key={10 * n}>
                      <span style={{ fontWeight: "bold" }}>
                        Question {n + 1}:
                      </span>
                      <ReactMarkdown
                        className="faqQuestion"
                        source={question
                          .toLowerCase()
                          .replace(/\. {2}/g, `.${String.fromCharCode(0xa0)} `)}
                      />
                    </div>
                    <div style={{ fontSize: "1.2em" }}>
                      <span style={{ fontWeight: "bold" }}>
                        Answer {n + 1}:
                      </span>
                      <ReactMarkdown
                        className="faqAnswer"
                        source={answer
                          .toLowerCase()
                          .replace(/\. {2}/g, `.${String.fromCharCode(0xa0)} `)}
                      />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}
