import React from "react";
import PropTypes from "prop-types";
import XLSX from "xlsx";

const datum1900 = new Date(Date.UTC(1899, 11, 30)); // day 1 is 1/1/1900
const datum1904 = datum1900 + 1462;
function xlsDatum(v, use1904) {
  return (Date.parse(v) - (use1904 ? datum1904 : datum1900)) / 86400000;
}

export class Column extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  };

  render() {
    throw new Error(
      "<Workbook.Column/> should be used only within a <Workbook.Sheet/>."
    );

    // eslint-disable-next-line
    return "";
  }
}

export class Sheet extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
    children: PropTypes.arrayOf((propValue, key) => {
      const type = propValue[key].type;
      if (type !== Column) {
        throw new Error("<Sheet> can only have <Column>'s as children. ");
      }
    }).isRequired,
  };

  createSheet() {
    const data =
      typeof this.props.data === "function"
        ? this.props.data()
        : this.props.data;

    const ws = {
      "!ref": XLSX.utils.encode_range({
        s: { r: 0, c: 0 },
        e: {
          r: 1 + data.length,
          c: React.Children.count(this.props.children),
        },
      }),
    };

    React.Children.forEach(this.props.children, (column, j) => {
      const cell = { v: column.props.label, t: "s" };
      ws[XLSX.utils.encode_cell({ r: 0, c: j })] = cell;
    });

    data.forEach((row, i) => {
      React.Children.forEach(this.props.children, (column, j) => {
        const value =
          typeof column.props.value === "function"
            ? column.props.value(row)
            : row[column.props.value];

        if (value === undefined || value === null) return;

        const cell = { v: value };
        if (typeof cell.v === "number") cell.t = "n";
        else if (typeof cell.v === "boolean") cell.t = "b";
        else if (typeof cell.v === "string") cell.t = "s";
        else if (cell.v instanceof Date) {
          cell.t = "n";
          cell.z = XLSX.SSF._table[14];
          cell.v = xlsDatum(cell.v);
        } else {
          throw new Error(
            `Worksheet cell data must be string, number, date, or boolean in column ${column.props.label}, row ${i}`
          );
        }

        ws[XLSX.utils.encode_cell({ r: i + 1, c: j })] = cell;
      });
    });

    return ws;
  }

  render() {
    throw new Error(
      "<Workbook.Sheet/> should be used only within a <Workbook/>."
    );

    // eslint-disable-next-line
    return "";
  }
}

export default class Workbook extends React.Component {
  static propTypes = {
    filename: PropTypes.string,
    element: PropTypes.any,
    children: function (props, propName) {
      React.Children.forEach(props[propName], (child) => {
        if (child.type !== Sheet) {
          throw new Error("<Workbook> can only have <Sheet>'s as children. ");
        }
      });
    },
  };

  download() {
    const wb = {
      SheetNames: [],
      Sheets: {},
    };

    React.Children.forEach(this.props.children, (sheet) => {
      wb.SheetNames.push(sheet.props.name);
      // typeof(sheet) is React.element, not Workbook.Sheet
      // JFC, Facebook! if you're going to be object-oriented don't eff it up!!!
      wb.Sheets[sheet.props.name] =
        sheet.type.prototype.createSheet.apply(sheet);
    });

    const filename = this.props.filename || "data.xlsx";
    const wbOpts = {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    };
    XLSX.writeFile(wb, filename, wbOpts);

    // saveAs(new Blob([wbout],
    //   {type:"application/octet-stream"}),
    //   this.props.filename || 'data.xlsx'
    // )
  }

  render() {
    return (
      <span onClick={this.download.bind(this)}>
        {this.props.element ? this.props.element : "Download"}
      </span>
    );
  }
}

Workbook.Sheet = Sheet;
Workbook.Column = Column;
