import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";

import MaidhawkAPI from "../api";

export default class Login extends Component {
  static propTypes = {};
  static defaultProps = {};
  state = {
    redirectToReferrer: false,
    username: "",
    password: "",
  };

  processLogin = () => {
    document.getElementById("processSpinner").style.visibility = "visible";

    const { roles } = this.props.location.state || { roles: ["ADMIN"] };
    MaidhawkAPI.call("POST", "/v1/login", {
      email: this.state.username,
      password: this.state.password,
      roles,
    }).then((result) => {
      document.getElementById("processSpinner").style.visibility = "hidden";

      if (result.token) {
        MaidhawkAPI.authenticate(result.token);

        this.setState({
          redirectToReferrer: true,
        });
      }
    });
  };

  componentDidMount() {
    // vital instrumentation :)
    console.log(`Using API server [${window.env.MAIDHAWK_SERVER}]`);
  }

  render() {
    const { redirectToReferrer } = this.state;
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    if (redirectToReferrer === true) {
      return <Redirect to={from} />;
    }

    const containerStyle = {
      backgroundColor: "rgb(224, 217, 217)",
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0px",
    };
    const loginFormStyle = {
      width: "400px",
      maxWidth: "400px",
      marginLeft: "auto",
      marginRight: "auto",
    };
    const textFieldFloatingLabelStyle = {
      color: "gray",
      fontSize: "16pt !important",
    };
    const textFieldStyle = {
      backgroundColor: "transparent",
    };

    return (
      <div
        className="row d-flex justify-content-center align-items-center"
        style={containerStyle}
      >
        <div className="col-md-12" style={loginFormStyle}>
          <div className="row">
            <img
              alt="maidhawk logo"
              src="resources/MaidHawkLogo.png"
              style={{ width: "100%" }}
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <TextField
                floatingLabelStyle={textFieldFloatingLabelStyle}
                style={textFieldStyle}
                fullWidth={true}
                floatingLabelText="Username"
                value={this.state.username}
                onChange={(e) =>
                  this.setState({
                    username: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TextField
                floatingLabelStyle={textFieldFloatingLabelStyle}
                style={textFieldStyle}
                fullWidth={true}
                floatingLabelText="Password"
                value={this.state.password}
                onChange={(e) =>
                  this.setState({
                    password: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{ marginBottom: "20px", marginTop: "20px" }}
            >
              <RaisedButton
                label="log in"
                style={{ width: "100%" }}
                onClick={this.processLogin}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
