import React from "react";
import ReactDOM from "react-dom";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";

// eslint-disable-next-line
import { cyan500 } from "material-ui/styles/colors";

import Body from "./components/layout/Body";
import Spinner from "./components/elements/Spinner";

import "./formatters";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

const muiTheme = getMuiTheme({
  palette: {
    textColor: "rgb(106, 171, 221)",
    textTransform: "lowercase",
  },
  appBar: {
    height: 150,
  },
});

ReactDOM.render(
  <MuiThemeProvider muiTheme={muiTheme}>
    <Body />
  </MuiThemeProvider>,
  document.getElementById("root")
);

var spinner = document.getElementById("processSpinner");
if (spinner) {
  ReactDOM.render(<Spinner />, spinner);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// TL;DR:  a service worker preloads and locally caches static site content
// for offline access.  (API calls and CDN media are not cached.)  The site
// server should send headers preventing cache of service-worker.js; even
// then all browser tabs must be closed in order that the service worker
// is unregistered & purged before new server content will be shown.  This
// is more radical even than quitting your browser with saved tabs.  (I haven't
// yet proved that even this works as hoped.)
serviceWorker.register();
