import React from "react";
import { NavLink } from "react-router-dom";

export default class AdminMenu extends React.Component {
  render() {
    const containerStyle = {
      textAlign: "left",
      alignItems: "center",
    };
    const linkStyle = {
      textDecoration: "none",
      color: "white",
      margin: "0px 10px",
      alignItems: "center",
    };
    const activeLinkStyle = {
      fontWeight: "bolder",
      color: "rgb(0,0,0)",
      fontSize: "1.2em",
    };
    return (
      <div style={containerStyle}>
        <NavLink to="/Admin" style={linkStyle} activeStyle={activeLinkStyle}>
          dashboard
        </NavLink>
        <NavLink to="/Orders" style={linkStyle} activeStyle={activeLinkStyle}>
          orders
        </NavLink>
        <NavLink
          to="/Customers"
          style={linkStyle}
          activeStyle={activeLinkStyle}
        >
          customers
        </NavLink>
        <NavLink
          to="/ServiceProviders"
          style={linkStyle}
          activeStyle={activeLinkStyle}
        >
          service providers
        </NavLink>
        <NavLink to="/Settings" style={linkStyle} activeStyle={activeLinkStyle}>
          settings
        </NavLink>
      </div>
    );
  }
}
