import React from "react";
import { NavLink } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";

import classnames from "classnames";

import IconButton from "material-ui/IconButton";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";

import MessageBox from "../../elements/MessageBox";
import MaidhawkAPI from "../../../api";

export default class Settings extends React.Component {
  static propTypes = {};
  static defaultProps = {};
  state = {
    prices: [],
    extraTypes: [],
    settings: [],
    users: [],

    dialogOpen: false,
    dialogTitle: "",
    entityType: null,
    price: {
      _id: null,
      bedrooms: 0,
      bathrooms: 0,
      price: 0,
      maxServiceTime: 0,
    },
    extraType: {
      _id: null,
      code: "",
      description: "",
      amount: 0,
      maxTime: 0,
    },
    setting: {
      _id: null,
      code: "",
      value: "",
    },
    user: {
      _id: null,
      name: "",
      lastName: "",
      email: "",
      password: "",
      mobileNumber: "",
    },

    messageBoxTitle: "",
    messageBoxBody: "",
    messageBoxResult: null,
    messageBoxOpen: false,
  };

  componentDidMount() {
    document.getElementById("processSpinner").style.visibility = "visible";

    Promise.all([
      MaidhawkAPI.call("GET", "/v1/prices").then((prices) =>
        prices.sort(
          (a, b) => a.bedrooms - b.bedrooms || a.bathrooms - b.bathrooms
        )
      ),
      MaidhawkAPI.call("GET", "/v1/extras"),
      MaidhawkAPI.call("GET", "/v1/settings"),
      MaidhawkAPI.call("GET", "/v1/admins"),
    ]).then(([prices, extraTypes, settings, users]) => {
      this.setState({
        prices,
        extraTypes,
        settings,
        users,
      });
    });
  }

  addPrice() {
    this.setState({
      dialogOpen: true,
      dialogTitle: "add price",
      entityType: 0,
      price: {
        bedrooms: 0,
        bathrooms: 0,
        price: 0,
        maxServiceTime: 0,
      },
    });
  }

  editPrice(priceLevel) {
    const { _id, bedrooms, bathrooms, price, maxServiceTime } = priceLevel;
    this.setState({
      dialogOpen: true,
      dialogTitle: "edit price",
      entityType: 0,
      price: { _id, bedrooms, bathrooms, price, maxServiceTime },
    });
  }

  savePrice() {
    document.getElementById("processSpinner").style.visibility = "visible";

    const { bedrooms, bathrooms, price, maxServiceTime } = this.state.price;
    let method = "POST",
      url = "/v1/prices";
    if ("_id" in this.state.price) {
      method = "PUT";
      url = `/v1/price/${this.state.price._id}`;
    }
    MaidhawkAPI.call(method, url, {
      bedrooms,
      bathrooms,
      price,
      maxServiceTime,
    }).then(() => {
      MaidhawkAPI.call("GET", "/v1/prices").then((prices) => {
        this.setState({
          dialogOpen: false,
          prices,
        });
      });
    });
  }

  deletePrice(id, bedrooms, bathrooms) {
    this.setState({
      messageBoxOpen: true,
      messageBoxTitle: "Delete price",
      messageBoxBody: `Are you sure you want to delete price for ${bedrooms} bedrooms / ${bathrooms} bathrooms?`,
      messageBoxResult: (result) => {
        if (result) {
          document.getElementById("processSpinner").style.visibility =
            "visible";

          MaidhawkAPI.call("DELETE", `/v1/price/${id}`).then(() => {
            MaidhawkAPI.call("GET", "/v1/prices").then((prices) => {
              this.setState({
                messageBoxOpen: false,
                prices,
              });
            });
          });
        } else {
          this.setState({
            messageBoxOpen: false,
          });
        }
      },
    });
  }

  addExtra() {
    this.setState({
      dialogOpen: true,
      dialogTitle: "add extra",
      entityType: 1,
      extraType: {
        code: "",
        description: "",
        amount: 0,
        maxTime: 0,
      },
    });
  }

  editExtra(extraType) {
    const { _id, code, description, amount, maxTime } = extraType;
    this.setState({
      dialogOpen: true,
      dialogTitle: "edit extra",
      entityType: 1,
      extraType: { _id, code, description, amount, maxTime },
    });
  }

  saveExtra() {
    document.getElementById("processSpinner").style.visibility = "visible";

    const { code, description, amount, maxTime } = this.state.extraType;
    let method = "POST",
      url = "/v1/extras";
    if ("_id" in this.state.extraType) {
      method = "PUT";
      url = `/v1/extra/${this.state.extraType._id}`;
    }
    MaidhawkAPI.call(method, url, { code, description, amount, maxTime }).then(
      () => {
        MaidhawkAPI.call("GET", "/v1/extras").then((extraTypes) => {
          this.setState({
            dialogOpen: false,
            extraTypes,
          });
        });
      }
    );
  }

  deleteExtraType(id, code) {
    this.setState({
      messageBoxOpen: true,
      messageBoxTitle: "Delete extra type",
      messageBoxBody: `Are you sure you want to delete this extra type, '${code}' ?`,
      messageBoxResult: (result) => {
        if (result) {
          document.getElementById("processSpinner").style.visibility =
            "visible";

          MaidhawkAPI.call("DELETE", `/v1/extra/${id}`).then(() => {
            MaidhawkAPI.call("GET", "/v1/extras").then((extraTypes) => {
              this.setState({
                messageBoxOpen: false,
                extraTypes,
              });
            });
          });
        } else {
          this.setState({
            messageBoxOpen: false,
          });
        }
      },
    });
  }

  addSetting() {
    this.setState({
      dialogOpen: true,
      dialogTitle: "add setting",
      entityType: 2,
      setting: {
        code: "",
        value: "",
      },
    });
  }

  editSetting(setting) {
    const { _id, code, value } = setting;
    this.setState({
      dialogOpen: true,
      dialogTitle: "edit setting",
      entityType: 2,
      setting: { _id, code, value },
    });
  }

  saveSetting() {
    document.getElementById("processSpinner").style.visibility = "visible";

    const { code, value } = this.state.setting;
    let method = "POST",
      url = "/v1/settings";
    if ("_id" in this.state.setting) {
      method = "PUT";
      url = `/v1/setting/${this.state.setting._id}`;
    }
    MaidhawkAPI.call(method, url, { code, value }).then(() => {
      MaidhawkAPI.call("GET", "/v1/settings").then((settings) => {
        this.setState({
          dialogOpen: false,
          settings,
        });
      });
    });
  }

  deleteSetting(id, code) {
    this.setState({
      messageBoxOpen: true,
      messageBoxTitle: "Delete setting",
      messageBoxBody: `Are you sure you want to delete this setting, '${code}' ?`,
      messageBoxResult: (result) => {
        if (result) {
          document.getElementById("processSpinner").style.visibility =
            "visible";

          MaidhawkAPI.call("DELETE", `/v1/setting/${id}`).then(() => {
            MaidhawkAPI.call("GET", "/v1/settings").then((settings) => {
              this.setState({
                messageBoxOpen: false,
                settings,
              });
            });
          });
        } else {
          this.setState({
            messageBoxOpen: false,
          });
        }
      },
    });
  }

  addAdmin() {
    this.setState({
      dialogOpen: true,
      dialogTitle: "add user",
      entityType: 3,
      user: {
        name: "",
        lastName: "",
        email: "",
        password: "",
        mobileNumber: "",
      },
    });
  }

  editAdmin(user) {
    const { _id, name, lastName, email, mobileNumber } = user;
    this.setState({
      dialogOpen: true,
      dialogTitle: "edit user",
      entityType: 4,
      user: { _id, name, lastName, email, password: "", mobileNumber },
    });
  }

  saveAdmin() {
    document.getElementById("processSpinner").style.visibility = "visible";

    const { name, lastName, mobileNumber, email, password } = this.state.user;
    let method = "POST",
      url = "/v1/admins";
    let user = { role: "ADMIN", name, lastName, mobileNumber, email, password };
    if ("_id" in this.state.user) {
      method = "PUT";
      url = `/v1/user/${this.state.user._id}`;
      user = { name, lastName, mobileNumber, email }; // changing role or password is unsupported
    }
    MaidhawkAPI.call(method, url, user).then(() => {
      MaidhawkAPI.call("GET", "/v1/admins").then((users) => {
        this.setState({ dialogOpen: false, users });
      });
    });
  }

  componentDidUpdate() {
    document.getElementById("processSpinner").style.visibility = "hidden";
  }

  render() {
    const requiredText = (value) =>
      value.length ? undefined : "this field is required";

    return (
      <div className={classnames("About", this.props.className)}>
        <div className="row" style={commonStyles.groupBoxContainer}>
          <div className="col-md-8" style={{ padding: "0" }}>
            <div className="row formPair">
              <div className="col-md-6" style={{ padding: "0px 10px" }}>
                <div className="row" style={commonStyles.groupBox}>
                  <div className="col-md-12" style={{ padding: "0px" }}>
                    <div className="row formPair">
                      <div className="col-md-6" style={tableTitleStyle}>
                        <span className="blackTitle">pricing</span>
                      </div>
                      <div className="col-md-6">
                        <FlatButton
                          label="add pricing"
                          labelStyle={commonStyles.headerButtonLabel}
                          style={actionButtonStyle}
                          fullWidth={true}
                          onClick={this.addPrice.bind(this)}
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ padding: "0px 0px 30px 0px" }}
                    >
                      <div className="col-md-12" style={{ padding: "0px" }}>
                        <ReactTable
                          className="-striped -highlight"
                          style={{ height: "500px" }}
                          data={this.state.prices}
                          defaultPageSize={10}
                          pageSize={10}
                          showPageSizeOptions={false}
                          autoScrollBodyContent={true}
                          filterable
                          defaultFilterMethod={(filter, row) =>
                            String(row[filter.id])
                              .toLowerCase()
                              .includes(filter.value.toLowerCase())
                          }
                          columns={[
                            {
                              Header: "",
                              accessor: "_id",
                              show: false,
                            },
                            {
                              id: "bedrooms",
                              Header: "bedrooms",
                              accessor: "bedrooms",
                              width: "100%",
                            },
                            {
                              id: "bathrooms",
                              Header: "bathrooms",
                              accessor: "bathrooms",
                              width: "100%",
                            },
                            {
                              id: "price",
                              Header: "price",
                              width: "100%",
                              style: { textAlign: "right" },
                              accessor: (row) => Intl.USMoney(row.price),
                            },
                            {
                              id: "maxServiceTime",
                              Header: "max service time",
                              width: "100%",
                              style: { textAlign: "right" },
                              accessor: (row) => Intl.Hours(row.maxServiceTime),
                            },
                            {
                              Header: "",
                              width: "50",
                              filterable: false,
                              Cell: (reactTableRow) => (
                                <IconButton
                                  style={{ height: "25px", padding: "0px" }}
                                >
                                  <DeleteIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.deletePrice(
                                        reactTableRow.original._id,
                                        reactTableRow.original.bedrooms,
                                        reactTableRow.original.bathrooms
                                      );
                                    }}
                                  />
                                </IconButton>
                              ),
                            },
                          ]}
                          getTrProps={(_, reactTableRow) => ({
                            onClick: () =>
                              this.editPrice(reactTableRow.original),
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6" style={{ padding: "0px 10px" }}>
                <div className="row" style={commonStyles.groupBox}>
                  <div className="col-md-12" style={{ padding: "0px" }}>
                    <div className="row formPair">
                      <div className="col-md-6" style={tableTitleStyle}>
                        <span className="blackTitle">extras</span>
                      </div>
                      <div className="col-md-6">
                        <FlatButton
                          label="add extra"
                          labelStyle={commonStyles.headerButtonLabel}
                          style={actionButtonStyle}
                          fullWidth={true}
                          onClick={this.addExtra.bind(this)}
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{ padding: "0px 0px 30px 0px" }}
                    >
                      <div className="col-md-12" style={{ padding: "0px" }}>
                        <ReactTable
                          className="-striped -highlight"
                          style={{ height: "500px" }}
                          data={this.state.extraTypes}
                          defaultPageSize={10}
                          pageSize={10}
                          showPageSizeOptions={false}
                          autoScrollBodyContent={true}
                          columns={[
                            {
                              Header: "",
                              accessor: "_id",
                              show: false,
                            },
                            {
                              id: "description",
                              Header: "description",
                              accessor: "description",
                              width: "100%",
                            },
                            {
                              id: "price",
                              Header: "price",
                              width: "100%",
                              style: { textAlign: "right" },
                              accessor: (row) => Intl.USMoney(row.amount),
                            },
                            {
                              id: "maxTime",
                              Header: "max service time",
                              width: "100%",
                              style: { textAlign: "right" },
                              accessor: (row) => Intl.Hours(row.maxTime),
                            },
                            {
                              Header: "",
                              width: "50",
                              Cell: (reactTableRow) => (
                                <IconButton
                                  style={{ height: "25px", padding: "0px" }}
                                >
                                  <DeleteIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.deleteExtraType(
                                        reactTableRow.original._id,
                                        reactTableRow.original.code
                                      );
                                    }}
                                  />
                                </IconButton>
                              ),
                            },
                          ]}
                          getTrProps={(_, reactTableRow) => ({
                            onClick: () =>
                              this.editExtra(reactTableRow.original),
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ padding: "20px 10px" }}>
              <div className="col-md-12" style={adminsContainerStyle}>
                <div className="row formPair">
                  <div className="col-md-6" style={adminsTitleStyle}>
                    <span className="blackTitle">administrators</span>
                  </div>
                  <div className="col-md-6">
                    <FlatButton
                      label="add user"
                      labelStyle={commonStyles.headerButtonLabel}
                      style={actionButtonStyle}
                      fullWidth={true}
                      onClick={this.addAdmin.bind(this)}
                    />
                  </div>
                </div>
                <div className="row" style={{ padding: "0px 0px 30px 0px" }}>
                  <div className="col-md-12" style={{ padding: "0px" }}>
                    <ReactTable
                      className="-striped -highlight"
                      style={{ height: "500px", width: "100%" }}
                      data={this.state.users}
                      defaultPageSize={8}
                      pageSize={8}
                      showPageSizeOptions={false}
                      autoScrollBodyContent={true}
                      filterable
                      defaultFilterMethod={(filter, row) =>
                        String(row[filter.id])
                          .toLowerCase()
                          .includes(filter.value.toLowerCase())
                      }
                      columns={[
                        {
                          Header: "",
                          accessor: "_id",
                          show: false,
                        },
                        {
                          id: "name",
                          Header: "name",
                          accessor: "name",
                          width: "100%",
                        },
                        {
                          id: "lastName",
                          Header: "lastName",
                          accessor: "lastName",
                          width: "100%",
                        },
                        {
                          id: "email",
                          Header: "email",
                          accessor: "email",
                          width: "100%",
                        },
                        {
                          id: "mobileNumber",
                          Header: "mobile number",
                          accessor: (row) => Intl.USTelephone(row.mobileNumber),
                          width: "100%",
                        },
                      ]}
                      getTrProps={(_, reactTableRow) => ({
                        onClick: () => this.editAdmin(reactTableRow.original),
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4" style={{ padding: "0" }}>
            <div className="row" style={{ padding: "0px 10px" }}>
              <div className="col-md-12" style={adminsContainerStyle}>
                <div className="row formPair">
                  <div className="col-md-6" style={tableTitleStyle}>
                    <span className="blackTitle">coupons</span>
                  </div>
                  <div className="col-md-6">
                    <NavLink
                      to="/Coupons"
                      style={linkStyle}
                      activeStyle={activeLinkStyle}
                    >
                      <FlatButton
                        label="coupons"
                        labelStyle={commonStyles.headerButtonLabel}
                        style={actionButtonStyle}
                        fullWidth={true}
                      />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ padding: "20px 10px" }}>
              <div className="col-md-12" style={adminsContainerStyle}>
                <div className="row formPair">
                  <div className="col-md-6" style={tableTitleStyle}>
                    <span className="blackTitle">settings</span>
                  </div>
                  <div className="col-md-6">
                    <FlatButton
                      label="add setting"
                      labelStyle={commonStyles.headerButtonLabel}
                      style={actionButtonStyle}
                      fullWidth={true}
                      onClick={this.addSetting.bind(this)}
                    />
                  </div>
                </div>
                <div className="row" style={{ padding: "0px 0px 30px 0px" }}>
                  <div className="col-md-12" style={{ padding: "0px" }}>
                    <ReactTable
                      className="-striped -highlight"
                      data={this.state.settings}
                      defaultPageSize={21}
                      pageSize={21}
                      showPageSizeOptions={false}
                      columns={[
                        {
                          Header: "",
                          accessor: "_id",
                          show: false,
                        },
                        {
                          Header: "code",
                          accessor: "code",
                          width: "100%",
                        },
                        {
                          Header: "value",
                          accessor: "value",
                          width: "100%",
                        },
                        {
                          Header: "",
                          width: "50",
                          Cell: (reactTableRow) => (
                            <IconButton
                              style={{ height: "25px", padding: "0px" }}
                            >
                              <DeleteIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.deleteSetting(
                                    reactTableRow.original._id,
                                    reactTableRow.original.code
                                  );
                                }}
                              />
                            </IconButton>
                          ),
                        },
                      ]}
                      getTrProps={(_, reactTableRow) => ({
                        onClick: () => this.editSetting(reactTableRow.original),
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          contentStyle={dialogContentStyle}
          actionsContainerStyle={dialogActionsContainerStyle}
          actions={[
            <div className="row">
              <div className="col-md-6">
                <FlatButton
                  label="save"
                  labelStyle={commonStyles.headerButtonLabel}
                  style={actionButtonStyle}
                  fullWidth={true}
                  onClick={() => {
                    switch (this.state.entityType) {
                      case 0:
                        return this.savePrice();
                      case 1:
                        return this.saveExtra();
                      case 2:
                        return this.saveSetting();
                      case 3:
                      case 4:
                        return this.saveAdmin();
                      default:
                        return;
                    }
                  }}
                />
              </div>
              <div className="col-md-6">
                <FlatButton
                  label="cancel"
                  labelStyle={commonStyles.headerButtonLabel}
                  style={actionButtonStyle}
                  fullWidth={true}
                  onClick={() => this.setState({ dialogOpen: false })}
                />
              </div>
            </div>,
          ]}
          autoScrollBodyContent={true}
          modal={true}
          open={this.state.dialogOpen}
        >
          <div className="row">
            <div className="col-md-12">
              {
                [
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-12">
                          {this.state.dialogTitle}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="bedrooms"
                            label="bedrooms"
                            floatingLabelText="bedrooms"
                            fullWidth
                            value={this.state.price.bedrooms}
                            onChange={(e) =>
                              this.setState({
                                price: {
                                  ...this.state.price,
                                  bedrooms: e.target.value,
                                },
                              })
                            }
                            type="number"
                            margin="normal"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="bathrooms"
                            label="bathrooms"
                            fullWidth
                            floatingLabelText="bathrooms"
                            value={this.state.price.bathrooms}
                            onChange={(e) =>
                              this.setState({
                                price: {
                                  ...this.state.price,
                                  bathrooms: e.target.value,
                                },
                              })
                            }
                            type="number"
                            margin="normal"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="price"
                            label="price"
                            floatingLabelText="price"
                            fullWidth
                            value={this.state.price.price}
                            onChange={(e) =>
                              this.setState({
                                price: {
                                  ...this.state.price,
                                  price: e.target.value,
                                },
                              })
                            }
                            type="number"
                            margin="normal"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="maxServiceTime"
                            label="max service time"
                            floatingLabelText="max service time"
                            fullWidth
                            value={this.state.price.maxServiceTime}
                            onChange={(e) =>
                              this.setState({
                                price: {
                                  ...this.state.price,
                                  maxServiceTime: e.target.value,
                                },
                              })
                            }
                            type="number"
                            margin="normal"
                          />
                        </div>
                      </div>
                    </div>
                  </div>,
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-12">
                          {this.state.dialogTitle}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="code"
                            label="code"
                            floatingLabelText="code"
                            fullWidth
                            value={this.state.extraType.code}
                            onChange={(e) =>
                              this.setState({
                                extraType: {
                                  ...this.state.extraType,
                                  code: e.target.value,
                                },
                              })
                            }
                            margin="normal"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="description"
                            label="description"
                            floatingLabelText="description"
                            fullWidth
                            value={this.state.extraType.description}
                            onChange={(e) =>
                              this.setState({
                                extraType: {
                                  ...this.state.extraType,
                                  description: e.target.value,
                                },
                              })
                            }
                            margin="normal"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="amount"
                            label="amount"
                            floatingLabelText="amount"
                            fullWidth
                            value={this.state.extraType.amount}
                            onChange={(e) =>
                              this.setState({
                                extraType: {
                                  ...this.state.extraType,
                                  amount: e.target.value,
                                },
                              })
                            }
                            type="number"
                            margin="normal"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="maxTime"
                            label="max time"
                            fullWidth
                            floatingLabelText="max time"
                            value={this.state.extraType.maxTime}
                            onChange={(e) =>
                              this.setState({
                                extraType: {
                                  ...this.state.extraType,
                                  maxTime: e.target.value,
                                },
                              })
                            }
                            type="number"
                            margin="normal"
                          />
                        </div>
                      </div>
                    </div>
                  </div>,
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-12">
                          {this.state.dialogTitle}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="code"
                            label="code"
                            fullWidth
                            floatingLabelText="code"
                            errorText={requiredText(this.state.setting.code)}
                            value={this.state.setting.code}
                            onChange={(e) =>
                              this.setState({
                                setting: {
                                  ...this.state.setting,
                                  code: e.target.value,
                                },
                              })
                            }
                            margin="normal"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="value"
                            label="value"
                            fullWidth
                            multiLine
                            floatingLabelText="value"
                            errorText={requiredText(this.state.setting.value)}
                            value={this.state.setting.value}
                            onChange={(e) =>
                              this.setState({
                                setting: {
                                  ...this.state.setting,
                                  value: e.target.value,
                                },
                              })
                            }
                            margin="normal"
                          />
                        </div>
                      </div>
                    </div>
                  </div>,
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-12">
                          {this.state.dialogTitle}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="name"
                            label="name"
                            floatingLabelText="name"
                            fullWidth
                            errorText={requiredText(this.state.user.name)}
                            value={this.state.user.name}
                            onChange={(e) =>
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  name: e.target.value,
                                },
                              })
                            }
                            margin="normal"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="lastName"
                            label="lastName"
                            floatingLabelText="lastName"
                            fullWidth
                            errorText={requiredText(this.state.user.lastName)}
                            value={this.state.user.lastName}
                            onChange={(e) =>
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  lastName: e.target.value,
                                },
                              })
                            }
                            margin="normal"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="mobileNumber"
                            label="mobileNumber"
                            fullWidth
                            floatingLabelText="mobileNumber"
                            errorText={requiredText(
                              this.state.user.mobileNumber
                            )}
                            value={this.state.user.mobileNumber}
                            onChange={(e) =>
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  mobileNumber: e.target.value,
                                },
                              })
                            }
                            margin="normal"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="email"
                            label="email"
                            floatingLabelText="email"
                            fullWidth
                            errorText={requiredText(this.state.user.email)}
                            value={this.state.user.email}
                            onChange={(e) =>
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  email: e.target.value,
                                },
                              })
                            }
                            margin="normal"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="password"
                            label="password"
                            type="password"
                            fullWidth
                            floatingLabelText="password"
                            errorText={requiredText(this.state.user.password)}
                            value={this.state.user.password}
                            onChange={(e) =>
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  password: e.target.value,
                                },
                              })
                            }
                            margin="normal"
                          />
                        </div>
                      </div>
                    </div>
                  </div>,
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-12">
                          {this.state.dialogTitle}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="name"
                            label="name"
                            floatingLabelText="name"
                            fullWidth
                            errorText={requiredText(this.state.user.name)}
                            value={this.state.user.name}
                            onChange={(e) =>
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  name: e.target.value,
                                },
                              })
                            }
                            margin="normal"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="lastName"
                            label="lastName"
                            floatingLabelText="lastName"
                            fullWidth
                            errorText={requiredText(this.state.user.lastName)}
                            value={this.state.user.lastName}
                            onChange={(e) =>
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  lastName: e.target.value,
                                },
                              })
                            }
                            margin="normal"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="mobileNumber"
                            label="mobileNumber"
                            fullWidth
                            floatingLabelText="mobileNumber"
                            errorText={requiredText(
                              this.state.user.mobileNumber
                            )}
                            value={this.state.user.mobileNumber}
                            onChange={(e) =>
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  mobileNumber: e.target.value,
                                },
                              })
                            }
                            margin="normal"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            id="email"
                            label="email"
                            floatingLabelText="email"
                            fullWidth
                            errorText={requiredText(this.state.user.email)}
                            value={this.state.user.email}
                            onChange={(e) =>
                              this.setState({
                                user: {
                                  ...this.state.user,
                                  email: e.target.value,
                                },
                              })
                            }
                            margin="normal"
                          />
                        </div>
                      </div>
                    </div>
                  </div>,
                ][this.state.entityType || 0]
              }
            </div>
          </div>
        </Dialog>

        <MessageBox
          open={this.state.messageBoxOpen}
          title={this.state.messageBoxTitle}
          message={this.state.messageBoxBody}
          onResult={(result) => {
            if (this.state.messageBoxResult !== null) {
              this.state.messageBoxResult(result);
            }
          }}
        />
      </div>
    );
  }
}

const commonStyles = {
  actionsContainerStyle: {
    padding: "0px 35px 20px 35px",
  },
  groupBoxContainer: {
    padding: "20px 30px 0px 30px",
  },
  groupBox: {
    backgroundColor: "white",
    border: "1px solid lightgray",
    borderRadius: "10px",
    padding: "5px 20px 0px 20px",
    color: "gray",
  },
  headerButtonContainer: {
    padding: "0px",
  },
  headerButtonLabel: {
    width: "100%",
    margin: "0px",
    padding: "0px",
    textTransform: "lowercase",
    fontSize: "10pt",
    lineHeight: "30px",
  },
  headerButton: {
    verticalAlign: "middle",
    color: "black",
    backgroundColor: "white",
    border: "1px solid gray",
    width: "100%",
    margin: "0px",
    lineHeight: "30px",
    height: "32px",
  },
  uploadInput: {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100%",
    opacity: 0,
  },
  dialogStyle: {
    width: "85%",
    maxWidth: "none",
    padding: "0px",
  },
  subDialogStyle: {
    width: "65%",
    maxWidth: "none",
    padding: "0px",
    color: "black",
    marginTop: "-10%",
  },
  headerDialogStyle: {
    background: "lightgray",
    color: "black",
    padding: "10px",
    height: "100%",
    alignContent: "center",
    border: "1px solid gray",
  },
  tableStyle: {
    width: "100%",
  },
  tableHeaderStyle: {
    fontSize: "12pt",
  },
};

const tableTitleStyle = {
  paddingLeft: "0px",
  fontSize: "1.1em",
  alignSelf: "center",
};

const adminsContainerStyle = {
  backgroundColor: "white",
  border: "1px solid lightgray",
  borderRadius: "10px",
  color: "gray",
  padding: "5px 20px",
};

const adminsTitleStyle = {
  paddingLeft: "0px",
  fontSize: "1.1em",
  alignSelf: "center",
};

const dialogContentStyle = {
  width: "auto",
  maxWidth: "450px",
  padding: "20px 20px 0px 20px",
  color: "black",
};

const dialogActionsContainerStyle = {
  padding: "20px 20px",
};

const actionButtonStyle = {
  ...commonStyles.headerButton,
  width: "auto",
  borderColor: "lightGray",
  margin: "10px 15px",
};

const linkStyle = {
  textDecoration: "none",
  color: "white",
  margin: "0px 10px",
  alignItems: "center",
};
const activeLinkStyle = {
  fontWeight: "bolder",
  color: "rgb(0,0,0)",
  fontSize: "1.2em",
};
