import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

// want contents of file:  without hacking react-scripts to use raw-loader
// for *.md file this is a url to fetch content from a static file
import originalTosMarkdown from "../../privacy.md";

export default class PrivacyPolicy extends React.Component {
  static propTypes = {
    api: PropTypes.shape({
      call: PropTypes.func,
    }).isRequired,
  };

  state = {
    tosMarkdown: "",
  };

  componentDidMount() {
    this.props.api
      .call("GET", "/v1/settings/privacyPolicy")
      .then((settings) => settings[0].value)
      // yeah, should implement GET /settings/termsofservice that does not require authentication!?
      // relying on browser-native fetch; consider a polyfill?!
      .catch(() =>
        fetch(originalTosMarkdown).then((response) => response.text())
      )
      .then((markdownText) => this.setState({ tosMarkdown: markdownText }));
  }

  render() {
    const mainStyle = {
      display: "flex",
      flexDirection: "column",
      padding: "96px 32px",
    };

    const bigImage = (
      <div
        className="row"
        style={{ width: "100%", height: "400px", overflow: "hidden" }}
      >
        <img
          alt="maidhawk department"
          style={{ width: "100%", height: "600px" }}
          src="resources/midtown-night.jpg"
        />
      </div>
    );

    return (
      <React.Fragment>
        <div className="container-fluid" style={{ marginTop: "-150px" }}>
          {bigImage}
          <main id="page" className="container" role="main" style={mainStyle}>
            <div className="row">
              <div className="col--md-12">
                <ReactMarkdown source={this.state.tosMarkdown} />
              </div>
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }
}
