import React from "react";

export default class Spinner extends React.Component {
  render() {
    const styles = {
      position: "absolute",
      left: "0px",
      width: "100%",
      height: "100%",
      zIndex: "1000",
      marginTop: "-100px",
      background: "transparent",
    };

    const dotStyles = {
      position: "absolute",
      top: "80px",
      left: "50%",
      width: "14px",
      height: "40px",
      marginTop: "20%",
      borderRadius: "10px",
      background: "gray",
    };

    const dots = [];
    for (var idx = 0; idx < 12; idx++) {
      const transform =
        "rotate(" + 30 * idx + "deg) translate(0,-40px) scale(0.83)";
      dots.push(<div key={idx} style={{ ...dotStyles, transform }} />);
    }

    return (
      <div className="uil-default-css" style={styles}>
        {dots}
      </div>
    );
  }
}
