import React from "react";

export default (props) => {
  const live = /^https:/i.test(props.href);
  return (
    <div style={flexCenterStyle}>
      <a
        className="buttonLandingPage disabled"
        style={appLinkStyle}
        target="_blank"
        rel="noopener noreferrer"
        href={live && props.href}
      >
        <img
          alt={props.imgAlt}
          src={props.imgSrc}
          style={{ height: "40px" }}
          onClick={live ? undefined : () => alert(props.href)}
        />
        <br />
      </a>
    </div>
  );
};

const flexCenterStyle = {
  display: "flex",
  justifyContent: "center",
};
const appLinkStyle = {
  backgroundColor: "black",
  width: "300px",
  // display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
