import React from "react";

import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import Radio from "material-ui/RadioButton";
import { black } from "material-ui/styles/colors";

import AppStoreLink from "../elements/AppStoreLink.js";
import MaidhawkAPI from "../../api";

class SignupTextField extends React.Component {
  render() {
    return (
      <TextField
        floatingLabelStyle={{ color: black }}
        inputStyle={{ color: "black" }}
        underlineStyle={{
          color: "rgb(106, 171, 221)",
          borderColor: "rgb(106, 171, 221)",
        }}
        underlineFocusStyle={{
          borderColor: "rgb(106, 171, 221)",
          borderWidth: "2px",
        }}
        style={{ color: "black", width: "100%" }}
        {...this.props}
      />
    );
  }
}

export default class Maids extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    phoneType: "",
    errorMessage: "",
    successMessage: "",
  };

  subscribeMaid = () => {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("POST", "/v1/maids/subscribe", {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      mobileNumber: this.state.mobileNumber,
      phoneType: this.state.phoneType,
    })
      .then((result) => {
        if (result.error) {
          this.setState({
            errorMessage: result.error,
          });
        } else {
          this.setState({
            successMessage: "Your request has been received.",
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.responseText,
        });
      });
  };

  componentDidUpdate() {
    document.getElementById("processSpinner").style.visibility = "hidden";
  }

  render() {
    return (
      <div className="container-fluid" style={{ marginTop: "-150px" }}>
        <div
          className="row"
          style={{ width: "100%", height: "400px", overflow: "hidden" }}
        >
          <img
            alt="maidhawk department"
            style={{ width: "100%", height: "600px" }}
            src="resources/midtown-night.jpg"
          />
        </div>

        <main
          id="page"
          className="row"
          role="main"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "56px 32px",
            alignItems: "center",
          }}
        >
          <div className="row">
            <div className="col-md-12">
              <h2>Service provider</h2>
              <p>
                If you're interested in becoming an independent Service Provider
                and offering your maid services to customers through the
                Maidhawk platform, please send us an email at{" "}
                <a href="mailto:{generalEmail}">
                  {window.env.MAIDHAWK_EMAIL || "info@hawkbrite.com"}
                </a>
                , or fill in this form below.
              </p>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div
                  className="col-md-12 subtitleLandingPage"
                  style={{ color: "black", textAlign: "center" }}
                >
                  <h3>For service providers</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <AppStoreLink
                    href={window.env.PROVIDER_APP_IOS_HREF}
                    imgAlt="iTunes Store"
                    imgSrc="resources/iosstore.png"
                  />
                </div>
                <div className="col-md-6">
                  <AppStoreLink
                    href={window.env.PROVIDER_APP_ANDROID_HREF}
                    imgAlt="Google Play"
                    imgSrc="resources/gplay.png"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              padding: "20px",
              backgroundColor: "#d6e4e60d",
              border: "1px solid #9E9E9E",
              boxShadow: "5px 5px 5px darkgrey",
              borderRadius: "4px",
            }}
          >
            <div className="row errorMessage">
              <div className="col-md-12">{this.state.errorMessage}</div>
            </div>
            <div className="row successMessage">
              <div className="col-md-12">{this.state.successMessage}</div>
            </div>
            <div
              className="row"
              style={{ width: "400px", color: "black !important" }}
            >
              <div className="col-md-12">
                <SignupTextField
                  value={this.state.firstName}
                  hintText="First Name"
                  floatingLabelText="First Name"
                  onChange={(e) =>
                    this.setState({
                      firstName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row" style={{ width: "400px" }}>
              <div className="col-md-12">
                <SignupTextField
                  value={this.state.lastName}
                  hintText="Last Name"
                  floatingLabelText="Last Name"
                  onChange={(e) =>
                    this.setState({
                      lastName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row" style={{ width: "400px" }}>
              <div className="col-md-12">
                <SignupTextField
                  value={this.state.email}
                  hintText="Email"
                  floatingLabelText="Email"
                  onChange={(e) =>
                    this.setState({
                      email: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row" style={{ width: "400px" }}>
              <div className="col-md-12">
                <SignupTextField
                  value={this.state.mobileNumber}
                  hintText="Mobile Number"
                  floatingLabelText="Mobile Number"
                  onChange={(e) =>
                    this.setState({
                      mobileNumber: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row" style={{ width: "400px", marginTop: "20px" }}>
              <div className="col-md-12">
                Do you use an iPhone or Android phone?
              </div>
            </div>
            <div className="row" style={{ width: "400px", marginTop: "20px" }}>
              <div className="col-md-12">
                <Radio
                  checked={this.state.phoneType === "android"}
                  value="android"
                  label="Android"
                  name="radio-button-demo"
                  onCheck={(e) =>
                    this.setState({
                      phoneType: e.target.value,
                    })
                  }
                />
                <Radio
                  checked={this.state.phoneType === "ios"}
                  value="ios"
                  label="IOS"
                  name="radio-button-demo"
                  onCheck={(e) =>
                    this.setState({
                      phoneType: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12"
                style={{
                  minWidth: "300px",
                  marginBottom: "20px",
                  marginTop: "20px",
                  color: "black !important",
                }}
              >
                <FlatButton
                  className="errorMessage"
                  label="send"
                  style={{
                    width: "100%",
                    border: "1px solid #9E9E9E",
                    color: "black !important",
                  }}
                  labelStyle={{
                    color: "black !important",
                  }}
                  onClick={this.subscribeMaid}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
