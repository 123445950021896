const serverUrl = window.env.MAIDHAWK_SERVER,
  authkey = "maidhawktoken";

class MaidhawkAPI {
  authenticate(token) {
    window.localStorage.setItem(authkey, token);
  }

  authenticated() {
    return authkey in window.localStorage;
  }

  signout() {
    return this.call("POST", "/v1/logout", {})
      .catch(() => true) // ignore and proceed
      .then(() => {
        // drop former token
        window.localStorage.removeItem(authkey);
      });
  }

  call(method, path, data) {
    const opts = {
      method,
      headers: new Headers({ Accept: "application/json" }),
    };

    // send with Authentication header
    if (this.authenticated()) {
      opts.headers.append(
        "Authorization",
        "Bearer " + window.localStorage.getItem(authkey)
      );
    }

    if (data) {
      // request content-type multipart/form-data
      // opts.body = new FormData();

      // request content-type application/x-www-form-urlencoded
      // opts.body = new URLSearchParams();
      // for (const p in data) {
      //   opts.body.append(p, data[p]);
      // }

      // request content-type application/json
      if (!(opts.headers instanceof Headers)) {
        opts.headers = new Headers();
      }
      opts.headers.append("Content-Type", "application/json");
      opts.body = JSON.stringify(data);
    }

    return fetch(serverUrl + path, opts)
      .catch((err) => {
        // transport failure
        console.error(err);
        return Promise.reject(err);
      })
      .then((res) => {
        if (res.ok) {
          return res.json().then((body) => {
            if (body.error) {
              return Promise.reject(Error(`${res.status}: ${body.error}`));
            }

            return body.data;
          });
        }

        if (res.status === 401) {
          // unauthorized
          window.localStorage.removeItem(authkey);

          // refresh token or go to login page
          // how to render <Redirect to=/> outside a component without a butt-load of duplication?
          // need to probably look into error domains??
          // for now just reload:
          // eslint-disable-next-line
          window.location = window.location;
        }

        // request failure
        // expect halt to further processing
        // succinctly communicate a friendly reason
        const { status, statusText } = res;
        return res
          .text()
          .then((body) => {
            try {
              // error is a dictionary of key-value pairs
              const { error } = JSON.parse(body);
              let reason = Object.keys(error)
                .map((k) => `${k}: ${error[k]}`)
                .join("\n");
              if (status < 500) {
                reason = reason.concat("\n\nFix the problem and try again.");
              }
              return reason;
            } catch (ex) {
              // got a non-JSON response
              return "server response is missing a structured explanation";
            }
          })
          .then((reason) => {
            alert(`${statusText}\n\n${reason}`);
            return Promise.reject(`${statusText}\n${reason}`);
          });
      });
  }
}

export default new MaidhawkAPI();
