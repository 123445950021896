import React from "react";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <div className="container-fluid" style={{ marginTop: "-150px" }}>
      <div
        className="row"
        style={{ width: "100%", height: "400px", overflow: "hidden" }}
      >
        <img
          alt="maidhawk department"
          style={{ width: "100%", height: "600px" }}
          src="resources/midtown-night.jpg"
        />
      </div>

      <main
        id="page"
        className="row"
        role="main"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "56px 32px",
        }}
      >
        <div className="row">
          <div className="col-md-3 col-12" role="navigation">
            <Link className="nav-link anchorLandingPage" to="/About">
              About
            </Link>
          </div>
          <div className="col-md-9 col-12">
            <div className="row">
              <h2>Location</h2>
              <p>
                MaidHawk is a service offered by HawkBright, Inc. providing the
                easiest, simplest way to keep your home clean.&nbsp; By offering
                our service on-demand, you can keep your home clean on your
                terms, when you're ready and available.&nbsp; It's the number
                one cleaning solution for busy people.
              </p>
            </div>
            <div>
              <br />
              <p>
                228 East 45th Street
                <br />
                New York, NY 10017
                <br />
              </p>
              <p>
                90 Canal St 4th Floor
                <br />
                Boston, MA 02114
                <br />
              </p>
              <p>
                {window.event.MAIDHAWK_TELEPHONE || "212 548 3283"}
                <br />
                Monday – Friday: 6:00 AM – 8:00 PM
                <br />
                Saturday: 6:00 AM – 8:30 PM
                <br />
                Sunday: 6:00 AM - 8:30 PM
                <br />
              </p>
              <h2>EMAIL</h2>
              <p>
                General Inquiries
                <br />
                <a href="mailto:{generalEmail}">
                  {window.env.MAIDHAWK_EMAIL || "info@hawkbrite.com"}
                </a>
                <br />
              </p>
              <h2>Partnerships</h2>
              <p>
                <a href="mailto:{corpEmail}">
                  {window.env.MAIDHAWK_EMAIL || "info@hawkbrite.com"}
                </a>
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
