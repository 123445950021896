import React from "react";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

export default class MessageBox extends React.Component {
  render() {
    const contentStyle = {
      width: "auto",
      maxWidth: "450px",
      padding: "20px 20px 0px 20px",
      color: "black",
    };
    const actionsContainerStyle = {
      padding: "20px 20px",
    };
    const actionLabelStyle = {
      width: "100%",
      margin: "0px",
      padding: "0px",
      textTransform: "lowercase",
      fontSize: "10pt",
      lineHeight: "30px",
    };
    const actionStyle = {
      verticalAlign: "middle",
      color: "black",
      backgroundColor: "white",
      border: "1px solid gray",
      width: "100%",
      margin: "0px",
      lineHeight: "30px",
      height: "32px",
    };

    return (
      <Dialog
        title={this.props.title}
        contentStyle={contentStyle}
        actionsContainerStyle={actionsContainerStyle}
        actions={[
          <div className="row">
            <div className="col-md-6">
              <FlatButton
                label="ok"
                labelStyle={actionLabelStyle}
                style={actionStyle}
                fullWidth={true}
                onClick={() => this.props.onResult(true)}
              />
            </div>
            <div className="col-md-6">
              <FlatButton
                label="cancel"
                labelStyle={actionLabelStyle}
                style={actionStyle}
                fullWidth={true}
                onClick={() => this.props.onResult(false)}
              />
            </div>
          </div>,
        ]}
        autoScrollBodyContent={true}
        onRequestClose={() => this.props.onResult(null)}
        modal={false}
        open={this.props.open}
      >
        <div className="row">
          <div className="col-md-12">
            <span style={{ color: "gray" }}>{this.props.message}</span>
          </div>
        </div>
      </Dialog>
    );
  }
}
