import React from "react";
import { Link } from "react-router-dom";

export default (props) => {
  return (
    <Link
      to="/request"
      className="buttonLandingPage disabled"
      style={RequestButtonStyle}
      rel="noopener noreferrer"
    >
      <img alt={props.imgAlt} src={props.imgSrc} style={{ height: "40px" }} />
    </Link>
  );
};

const RequestButtonStyle = {
  backgroundColor: "black",
  width: "300px",
  // display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
