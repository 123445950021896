import React from "react";
import Moment from "react-moment";
import AuditLog from "../../elements/AuditLog";
import Workbook from "../../elements/Workbook";
import ReactStars from "react-stars";
import ReactTable from "react-table";
import "react-table/react-table.css";

import classnames from "classnames";

import Dialog from "material-ui/Dialog";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";

import MaidhawkAPI from "../../../api";
import {
  customerStatusDescription,
  reactMomentFormatDate,
  reactMomentFormatDateTime,
} from "../../../constants";

export default class Customers extends React.Component {
  static propTypes = {};
  static defaultProps = {};
  state = {
    customers: [],
    customerId: false,
  };

  componentDidMount() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("GET", "/v1/customers").then((customers) =>
      this.setState({ customers, customerId: false })
    );
  }

  componentDidUpdate() {
    document.getElementById("processSpinner").style.visibility = "hidden";
  }

  render() {
    return (
      <div className={classnames("About", this.props.className)}>
        <div style={{ textAlign: "right", padding: "20px" }}>
          <Workbook
            filename={"customers" + new Date().toString() + ".xlsx"}
            element={<RaisedButton label="export to excel" />}
          >
            <Workbook.Sheet data={this.state.customers} name="Sheet A">
              <Workbook.Column label="customer name" value="name" />
              <Workbook.Column label="last name" value="lastName" />
              <Workbook.Column label="email" value="email" />
              <Workbook.Column
                label="mobile number"
                value={(customer) => Intl.USTelephone(customer.mobileNumber)}
              />
              <Workbook.Column label="number of orders" value="numberOrder" />
              <Workbook.Column label="last order on" value="lastOrderOn" />
              <Workbook.Column
                label="status"
                value={(customer) => customerStatusDescription[customer.status]}
              />
            </Workbook.Sheet>
          </Workbook>
        </div>
        <ReactTable
          className="-striped -highlight"
          data={this.state.customers}
          defaultPageSize={10}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .includes(filter.value.toLowerCase())
          }
          columns={[
            {
              Header: "id",
              accessor: "_id",
              show: false,
            },
            {
              Header: "customer name",
              accessor: "name",
            },
            {
              Header: "last name",
              accessor: "lastName",
            },
            {
              Header: "email",
              accessor: "email",
            },
            {
              id: "mobileNumber",
              Header: "mobile number",
              accessor: (customer) => Intl.USTelephone(customer.mobileNumber),
            },
            {
              id: "lastOrderOn",
              Header: "last order",
              accessor: (customer) =>
                customer.orders.length > 0
                  ? new Date(customer.orders[0].dateCreated)
                  : undefined,
              Cell: (cell) =>
                cell.value ? (
                  <Moment format={reactMomentFormatDate} date={cell.value} />
                ) : (
                  ""
                ),
              filterable: false,
            },
            {
              id: "status",
              Header: "status",
              accessor: "status",
              Cell: (cell) =>
                customerStatusDescription[cell.value].toLowerCase(),
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={(event) => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                >
                  <option value="all">show all</option>
                  <option value="ACTIVE">active</option>
                  <option value="INACTIVE">blocked</option>
                </select>
              ),
            },
          ]}
          getTrProps={(_, reactTableRow) => ({
            onClick: () =>
              this.setState({ customerId: reactTableRow.original._id }),
          })}
        />
        {this.state.customerId && (
          <CustomerDialog
            customerId={this.state.customerId}
            close={() => this.componentDidMount()}
          />
        )}
      </div>
    );
  }
}

class CustomerDialog extends React.Component {
  static propTypes = {};
  static defaultProps = {};
  state = {
    customer: { orders: [], audit: [] },
    addNoteDialogOpen: false,
    newNote: undefined,
  };

  componentDidMount() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("GET", `/v1/customer/${this.props.customerId}`).then(
      (customer) =>
        MaidhawkAPI.call("GET", `/v1/user/${customer._id}/audit`).then(
          (audit) => {
            customer.audit = audit;
            this.setState({ customer });
          }
        )
    );
  }

  addCustomerNote() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("POST", `/v1/user/${this.props.customerId}/notes`, {
      message: this.state.newNote,
    }).then((note) => {
      note.changedBy = { email: "you" };
      note.eventDate = new Date();

      const { customer } = this.state;
      customer.audit.splice(0, 0, note);
      this.setState({ customer, addNoteDialogOpen: false });
    });
  }

  setCustomerActive(active) {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("PUT", `/v1/customer/${this.props.customerId}/active`, {
      active,
    }).then((response) => response.success && this.componentDidMount());
  }

  componentDidUpdate() {
    document.getElementById("processSpinner").style.visibility = "hidden";
  }

  render() {
    return (
      <Dialog
        contentStyle={commonStyles.dialogStyle}
        actionsContainerStyle={commonStyles.actionsContainerStyle}
        actions={[
          <FlatButton
            label="Ok"
            labelStyle={commonStyles.headerButtonLabel}
            style={actionButtonStyle}
            onClick={() => this.props.close()}
          />,
        ]}
        autoScrollBodyContent={true}
        modal={true}
        fullScreen={true}
        open={true}
      >
        <div className="row" style={commonStyles.headerDialogStyle}>
          <div className="col-md-2" style={commonStyles.headerButtonContainer}>
            <FlatButton
              label={
                this.state.customer.status === "INACTIVE"
                  ? "re-activate customer"
                  : "block customer"
              }
              labelPosition="before"
              labelStyle={commonStyles.headerButtonLabel}
              style={commonStyles.headerButton}
              fullWidth={true}
              containerElement="label"
              onClick={() =>
                this.setCustomerActive(
                  this.state.customer.status === "INACTIVE"
                )
              }
            />
          </div>
        </div>
        <div className="row" style={commonStyles.groupBoxContainer}>
          <div className="col-md-8" style={{ padding: "0px" }}>
            <div className="row" style={leftGroupBoxTopStyle}>
              <div className="col-md-12">
                <div className="row ">
                  <span className="blackTitle">customer</span>
                </div>
                <div className="row ">
                  <div className="col-md-3">
                    <span className="formLabel">Name</span>
                  </div>
                  <div className="col-md-9">
                    <span>
                      {this.state.customer.name} {this.state.customer.lastName}
                    </span>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-3">
                    <span className="formLabel">Email</span>
                  </div>
                  <div className="col-md-9">
                    <span>{this.state.customer.email}</span>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-3">
                    <span className="formLabel">Cell Phone</span>
                  </div>
                  <div className="col-md-9">
                    <span>
                      {Intl.USTelephone(this.state.customer.mobileNumber)}
                    </span>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-3">
                    <span className="formLabel">Rating</span>
                  </div>
                  <div className="col-md-9">
                    <ReactStars
                      count={5}
                      value={this.state.customer.averageMaidRating}
                      edit={false}
                      size={18}
                      color2={"#ffd700"}
                    />{" "}
                    (average of {this.state.customer.countMaidRatings} ratings)
                  </div>
                </div>
                <div className="row formPair">
                  <div className="col-md-3">
                    <span className="formLabel">Past Orders</span>
                  </div>
                  <div className="col-md-9">
                    <span>{this.state.customer.orders.length}</span>
                  </div>
                </div>
                <div className="row formPair">
                  <div className="col-md-3">
                    <span className="formLabel">Status</span>
                  </div>
                  <div className="col-md-9">
                    <span>
                      {customerStatusDescription[this.state.customer.status]}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={leftGroupBoxStyle}>
              <div className="col-md-12">
                <div className="row formPair">
                  <span className="blackTitle">Past Orders</span>
                </div>
                <div className="row formPair">
                  <ReactTable
                    className="-striped -highlight"
                    style={commonStyles.tableStyle}
                    data={this.state.customer.orders}
                    pageSize={10}
                    defaultPageSize={10}
                    showPageSizeOptions={false}
                    autoScrollBodyContent={true}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                      String(row[filter.id])
                        .toLowerCase()
                        .includes(filter.value.toLowerCase())
                    }
                    columns={[
                      {
                        Header: "order number",
                        headerStyle: commonStyles.tableHeaderStyle,
                        accessor: "serialId",
                      },
                      {
                        Header: "order date",
                        headerStyle: commonStyles.tableHeaderStyle,
                        accessor: "dateCreated",
                        Cell: (cell) =>
                          cell.value && (
                            <Moment
                              format={reactMomentFormatDateTime}
                              date={cell.value}
                            />
                          ),
                      },
                      {
                        id: "amount",
                        Header: "order amount",
                        headerStyle: commonStyles.tableHeaderStyle,
                        style: { textAlign: "right" },
                        accessor: (order) => Intl.USMoney(order.amount),
                      },
                      {
                        Header: "service provider",
                        headerStyle: commonStyles.tableHeaderStyle,
                        accessor: "maidAssigned.email",
                      },
                      {
                        Header: "address",
                        headerStyle: commonStyles.tableHeaderStyle,
                        id: "address",
                        accessor: ({ street, unit, city, state, zip }) =>
                          Intl.USAddress(street, unit, city, state, zip),
                      },
                      {
                        Header: "order details",
                        headerStyle: commonStyles.tableHeaderStyle,
                        id: "details",
                        accessor: (row) => {
                          const { bedrooms, bathrooms } = row.details[0];
                          return Intl.pricing`${bedrooms} ${bathrooms}`; // tagged template
                        },
                      },
                      {
                        Header: "rating",
                        headerStyle: commonStyles.tableHeaderStyle,
                        accessor: "maidRating",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4" style={{ padding: "0px 0px 0px 10px" }}>
            <div className="row" style={rightGroupBoxStyle}>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6 col-6">
                    <span className="blackSubTitle">Notes</span>
                  </div>
                  <div
                    className="col-md-6 col-6"
                    style={{ textAlign: "right" }}
                  >
                    <FlatButton
                      label="Add Note"
                      labelStyle={commonStyles.headerButtonLabel}
                      style={actionButtonStyle}
                      onClick={() =>
                        this.setState({
                          addNoteDialogOpen: true,
                          newNote: "",
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-12"
                    style={{ height: "500px", overflowY: "scroll" }}
                  >
                    <AuditLog log={this.state.customer.audit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          contentStyle={{ width: "550px" }}
          width="300px"
          actionsContainerStyle={commonStyles.actionsContainerStyle}
          actions={[
            <FlatButton
              label="save"
              labelStyle={commonStyles.headerButtonLabel}
              style={actionButtonStyle}
              onClick={() => this.addCustomerNote()}
            />,
            <FlatButton
              label="Cancel"
              labelStyle={commonStyles.headerButtonLabel}
              style={actionButtonStyle}
              onClick={() => this.setState({ addNoteDialogOpen: false })}
            />,
          ]}
          repositionOnUpdate={false}
          modal={true}
          open={this.state.addNoteDialogOpen}
        >
          <div className="row" style={{ padding: "10px 15px" }}>
            <div className="col-md-12">
              <div className="row  formPair">
                <div className="col-md-12">
                  <TextField
                    floatingLabelStyle={textFieldFloatingLabelStyle}
                    style={textFieldStyle}
                    fullWidth={true}
                    multiLine={true}
                    rows={3}
                    rowsMax={4}
                    floatingLabelText="add customer note"
                    value={this.state.newNote}
                    onChange={(e) => this.setState({ newNote: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </Dialog>
    );
  }
}

const commonStyles = {
  actionsContainerStyle: {
    padding: "10px 35px 10px 35px",
  },
  groupBoxContainer: {
    padding: "10px 0px",
  },
  groupBox: {
    backgroundColor: "white",
    border: "1px solid gray",
    borderRadius: "10px",
    padding: "10px 0px",
    color: "gray",
  },
  headerButtonContainer: {
    padding: "0px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  headerButtonLabel: {
    width: "100%",
    margin: "0px",
    padding: "0px",
    textTransform: "lowercase",
    fontSize: "10pt",
    lineHeight: "30px",
  },
  headerButton: {
    verticalAlign: "middle",
    color: "black",
    backgroundColor: "white",
    border: "1px solid gray",
    width: "100%",
    margin: "0px",
    lineHeight: "30px",
    height: "32px",
  },
  uploadInput: {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100%",
    opacity: 0,
  },
  dialogStyle: {
    width: "85%",
    maxWidth: "none",
    padding: "0px",
  },
  headerDialogStyle: {
    background: "lightgray",
    color: "black",
    padding: "10px",
    height: "100%",
    alignContent: "center",
    border: "1px solid gray",
  },
  tableStyle: {
    width: "100%",
    height: "300px",
  },
  tableHeaderStyle: {
    fontSize: "12pt",
  },
};
const actionButtonStyle = {
  ...commonStyles.headerButton,
  width: "auto",
};
const leftGroupBoxTopStyle = {
  ...commonStyles.groupBox,
  width: "100%",
  marginRight: "5px",
  marginBottom: "20px",
  padding: "10px 0px",
};
const leftGroupBoxStyle = {
  ...commonStyles.groupBox,
  marginRight: "5px",
};
const rightGroupBoxStyle = {
  ...commonStyles.groupBox,
  height: "100%",
};
const textFieldFloatingLabelStyle = {
  color: "gray",
  fontSize: "16pt !important",
};
const textFieldStyle = {
  backgroundColor: "white",
};
