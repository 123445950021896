import React from "react";

import AppStoreLink from "../elements/AppStoreLink.js";
import RequestButton from "../elements/RequestButton.js";

export default class Home extends React.Component {
  render() {
    return (
      <div
        className="container-fluid"
        style={{
          top: "0px",
          position: "absolute",
        }}
      >
        <div
          className="row"
          style={{
            width: "100%",
            bottom: "100px",
            maxHeight: "950px",
            marginBottom: "50px",
          }}
          data-content-field="main-image"
          data-collection-id="537aea49e4b054f58235d8e5"
          data-edit-main-image="Banner"
          data-annotation-alignment="bottom left"
        >
          <img
            alt="maidhawk department"
            className="img"
            style={{
              bottom: "100px",
              width: "100%",
              filter: "brightness(70%)",
            }}
            src="resources/thecouch.jpg"
          />

          <div className="firstPageBlock">
            <div className="row">
              <div className="col-md-12">
                <h3 className="firstPagePhoneNumber">
                  {window.env.MAIDHAWK_TELEPHONE || "212 548 3283"}
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p className="subtitleLandingPage">
                  maid on demand - now in soft release in NYC and Boston
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>
                  <strong>"cleaning services now"</strong>
                </p>
              </div>
            </div>
            <div className="firstPageItmes">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <h3 style={{ color: "white" }}>
                        send maid{" "}
                        <span style={{ fontWeight: "bold" }}>now, </span>
                        download our app
                      </h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3" />
                    <div className="col-md-3">
                      <AppStoreLink
                        href={window.env.CUSTOMER_APP_IOS_HREF}
                        imgAlt="iTunes Store"
                        imgSrc="resources/iosstore.png"
                      />
                    </div>
                    <div className="col-md-3">
                      <AppStoreLink
                        href={window.env.CUSTOMER_APP_ANDROID_HREF}
                        imgAlt="Google Play"
                        imgSrc="resources/gplay.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        <h3 style={{ color: "white" }}>
                          send maid{" "}
                          <span style={{ fontWeight: "bold" }}>later,</span>{" "}
                          schedule here
                        </h3>
                      </p>
                    </div>
                  </div>
                  <RequestButton
                    imgAlt="Schedule"
                    imgSrc="resources/schedule_icon.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
