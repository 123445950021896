import React from "react";

import _ from "lodash";
import classnames from "classnames";

import BarChart from "../../elements/Barchart";
import MaidhawkAPI from "../../../api";

export default class Home extends React.Component {
  state = {
    orders: [],
    customers: [],
    sales: [],
    maids: [],
  };

  componentDidMount() {
    document.getElementById("processSpinner").style.visibility = "visible";

    Promise.all([
      MaidhawkAPI.call("GET", "/v1/stats/ytd/requests").then((orders) => {
        if (orders.length > 0) {
          orders = orders.map((x) => {
            return {
              time: new Date(new Date(x.dateCreated).setHours(0, 0, 0)),
              value: 1,
            };
          });

          return _.toPairs(_.groupBy(orders, "time")).map((x) => {
            return {
              time: new Date(x[0]),
              value: x[1].length,
            };
          });
        }

        return [];
      }),
      MaidhawkAPI.call("GET", "/v1/stats/ytd/customers").then((customers) => {
        if (customers.length > 0) {
          customers = customers.map((x) => {
            return {
              time: new Date(new Date(x.dateRegistered).setHours(0, 0, 0)),
              value: 1,
            };
          });

          return _.toPairs(_.groupBy(customers, "time")).map((x) => {
            return {
              time: new Date(x[0]),
              value: x[1].length,
            };
          });
        }

        return [];
      }),
      MaidhawkAPI.call("GET", "/v1/stats/ytd/maids").then((maids) => {
        if (maids.length) {
          maids = maids.map((x) => {
            return {
              time: new Date(new Date(x.dateRegistered).setHours(0, 0, 0)),
              value: 1,
            };
          });

          return _.toPairs(_.groupBy(maids, "time")).map((x) => {
            return {
              time: new Date(x[0]),
              value: x[1].length,
            };
          });
        }

        return [];
      }),
      MaidhawkAPI.call("GET", "/v1/stats/ytd/sales").then((sales) => {
        if (sales.length) {
          sales = sales.map((x) => {
            return {
              time: new Date(new Date(x.dateCreated).setHours(0, 0, 0)),
              value: 1, // we're just counting
            };
          });

          return _.toPairs(_.groupBy(sales, "time")).map((x) => {
            return {
              time: new Date(x[0]),
              value: x[1].length, // you know, you could sum the array to get revenue rather than count
            };
          });
        }

        return [];
      }),
    ]).then(([orders, customers, maids, sales]) =>
      this.setState({
        orders,
        customers,
        maids,
        sales,
      })
    );
  }

  componentDidUpdate() {
    document.getElementById("processSpinner").style.visibility = "hidden";
  }

  render() {
    const rowPaddingStyle = {
      padding: "15px",
    };
    const boxStyle = {
      borderRadius: "5px",
      padding: "15px",
    };
    const boxBorderStyle = {
      border: "1px solid gray",
    };

    return (
      <div className={classnames("About", this.props.className)}>
        <div className="row" style={rowPaddingStyle}>
          <div className="col-md-6" style={boxStyle}>
            <div className="row" style={boxBorderStyle}>
              <BarChart
                id="last"
                title={this.state.orders.length + " orders"}
                xLegend={"time"}
                yLegend={"orders"}
                data={this.state.orders}
                callBack={() => {}}
              />
            </div>
          </div>
          <div className="col-md-6" style={boxStyle}>
            <div className="row" style={boxBorderStyle}>
              <BarChart
                id="order"
                title={this.state.customers.length + " customers signed up"}
                xLegend={"time"}
                yLegend={"customers"}
                data={this.state.customers}
                callBack={() => {}}
              />
            </div>
          </div>
        </div>
        <div className="row" style={rowPaddingStyle}>
          <div className="col-md-6" style={boxStyle}>
            <div className="row" style={boxBorderStyle}>
              <BarChart
                id="last2"
                title={this.state.sales.length + " sales"}
                xLegend={"time"}
                yLegend={"sales"}
                data={this.state.sales}
                callBack={() => {}}
              />
            </div>
          </div>
          <div className="col-md-6" style={boxStyle}>
            <div className="row" style={boxBorderStyle}>
              <BarChart
                id="order3"
                title={this.state.maids.length + " service providers signed up"}
                xLegend={"time"}
                yLegend={"service providers"}
                data={this.state.maids}
                callBack={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
