import React from "react";
// import PropTypes from "prop-types";
import { fromJS } from "immutable";

export default class EditableLabel extends React.Component {
  static propTypes = {
    //   initialValue: PropTypes.string.isRequired,
    //   save: PropTypes.func.isRequired,
    //   labelClass: PropTypes.string,
    //   inputClass: PropTypes.string,
    //   disableKeys: PropTypes.boolean
  };

  constructor(props) {
    super(props);

    this.state = {
      data: fromJS({
        view: "label",
        initialValue: props.initialValue,
        displayValue: props.initialValue,
      }),
    };
  }

  componentWillReceiveProps(newProps) {
    const { view, initialValue } = this.state.data.toJS();
    if (newProps.initialValue !== initialValue) {
      this.setState({
        data: fromJS({
          view,
          initialValue: newProps.initialValue,
          displayValue: newProps.initialValue,
        }),
      });
    }
  }

  showText() {
    const { data } = this.state;
    this.setState({
      data: data.mergeDeep({
        view: "text",
      }),
    });
  }

  showLabel(value) {
    const { data } = this.state;
    this.setState({
      data: data.mergeDeep({
        view: "label",
        displayValue: value,
      }),
    });
  }

  componentDidUpdate() {
    // element must have focus so that onBlur can be fired when focus is lost
    this.textInput && this.textInput.focus();
  }

  render() {
    const { view, displayValue } = this.state.data.toJS();
    return view === "text"
      ? this.renderAsInput(displayValue)
      : this.renderAsLabel(displayValue);
  }

  renderAsInput(displayValue) {
    const { inputClass } = this.props;
    return (
      <div>
        <input
          type="text"
          defaultValue={displayValue}
          ref={(input) => (this.textInput = input)}
          className={inputClass !== undefined ? inputClass : ""}
          tabIndex={this.props.tabIndex || 0}
          onKeyUp={(e) => {
            if (this.props.disableKeys === true) {
              return;
            }

            if (e.key === "Escape") {
              // restore intial value
              const { initialValue } = this.state.data.toJS();
              this.showLabel(initialValue);
            } else if (e.key === "Enter") {
              // same as onBlur
              this.showLabel(e.target.value);
              this.props.save(e.target.value); // probably updates initial value through changing props
            }
          }}
          onBlur={(e) => {
            this.showLabel(e.target.value);
            this.props.save(e.target.value);
          }}
        />
      </div>
    );
  }

  renderAsLabel(displayValue) {
    const { labelClass } = this.props;
    return (
      <div>
        <span
          ref={() => (this.textInput = undefined)}
          className={labelClass !== undefined ? labelClass : ""}
          tabIndex={this.props.tabIndex || 0}
          onFocus={(e) => {
            this.showText();
          }}
        >
          {displayValue || "(add)"}
        </span>
      </div>
    );
  }
}
