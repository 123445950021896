import React from "react";

import AppBar from "material-ui/AppBar";
import IconButton from "material-ui/IconButton";

import AdminMenu from "./AdminMenu";

export default class AdminHeader extends React.Component {
  render() {
    const styles = {
      textAlign: "left",
      alignItems: "center",
      margin: "0px",
      backgroundColor: "rgb(106, 171, 221)",
      color: "rgb(106, 171, 221)",
    };
    const titleStyle = {
      lineHeight: "auto",
      height: "auto",
      margin: "0px",
    };

    return (
      <AppBar
        style={styles}
        title={<span style={styles}>maidhawk admin portal</span>}
        titleStyle={titleStyle}
        iconStyleLeft={{ marginTop: "0px" }}
        iconElementLeft={<IconButton />}
        iconStyleRight={titleStyle}
        iconElementRight={<AdminMenu style={styles} />}
      />
    );
  }
}
