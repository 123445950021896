import React from "react";

import "antd/dist/antd.css";
import {
  Form,
  Input,
  Cascader,
  Row,
  Col,
  Button,
  DatePicker,
  TimePicker,
  Slider,
  InputNumber,
} from "antd";
import TextArea from "antd/lib/input/TextArea";

import MaidhawkAPI from "../../api";

const frequency = [
  {
    value: "once",
    label: "one Time Only",
  },
  {
    value: "weekly",
    label: "weekly",
  },
  {
    value: "biweekly",
    label: "bi-Weekly",
  },
  {
    value: "monthly",
    label: "monthly",
  },
];

class FormData extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    slideInputValue: 0,
    totalPrice: 0,
    price: 0,
    customerName: "",
    phone: "",
    email: "",
    serviceAddress: "",
    serviceDate: "",
    serviceTime: "",
    couponCode: "",
    serviceFrequency: "",
    commentsInstructions: "",
    wfHourlyPriceUSD: 35,
    wfTaxAMTPercentage: 8.875,
    wfTaxDescription: "",
  };

  componentDidMount() {
    MaidhawkAPI.call("GET", "/v1/settings/wfHourlyPriceUSD")
      .then((result) => {
        if (result.error) {
          this.setState({
            errorMessage: result.error,
          });
        } else {
          this.setState({
            wfHourlyPriceUSD: parseInt(result[0].value),
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.responseText,
        });
      });
    MaidhawkAPI.call("GET", "/v1/settings/wfTaxAMTPercentage")
      .then((result) => {
        if (result.error) {
          this.setState({
            errorMessage: result.error,
          });
        } else {
          this.setState({
            wfTaxAMTPercentage: parseFloat(result[0].value),
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.responseText,
        });
      });
    MaidhawkAPI.call("GET", "/v1/settings/wfTaxDescription")
      .then((result) => {
        if (result.error) {
          this.setState({
            errorMessage: result.error,
          });
        } else {
          this.setState({
            wfTaxDescription: result[0].value,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.responseText,
        });
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onChangeValue();
        var data = {
          customerName: values.customerName,
          phone: values.phone,
          email: values.email,
          serviceAddress: values.serviceAddress,
          serviceDate: values.serviceDate.format("MM/DD/YYYY"),
          serviceTime: values.serviceTime.format("h:mm a"),
          couponCode: values.couponCode,
          serviceFrequency: values.serviceFrequency,
          commentsInstructions: values.commentsInstructions,
          requireTime: this.state.slideInputValue,
          totalPrice: this.state.totalPrice,
        };

        MaidhawkAPI.call("POST", "/v1/requests/future/", data)
          .then((result) => {
            if (result.error) {
              this.setState({
                errorMessage: result.error,
              });
            } else {
              this.setState({
                successMessage: "Your request has been received.",
              });
            }
          })
          .catch((error) => {
            this.setState({
              errorMessage: error.responseText,
            });
          });
      }
    });
  };

  onChangeSlideValue = (value) => {
    this.setState({
      slideInputValue: value,
      price: value * this.state.wfHourlyPriceUSD,
      totalPrice:
        value * this.state.wfHourlyPriceUSD +
        Math.round(
          (value *
            this.state.wfHourlyPriceUSD *
            this.state.wfTaxAMTPercentage) /
            100
        ),
    });
  };

  validateUSTelephone = (rule, value, callback) => {
    // accept all digits or area-exchange-number formats (area can't begin with 0)
    if (/^[1-9]\d{2}-\d{3}-\d{4}$/.test(value)) {
      callback();
    } else {
      callback("use format 123-456-7890");
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { slideInputValue } = this.state;

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    return (
      <div className="container" style={{ fontFamily: "Courier New" }}>
        <p className="h4 text-center mb-4">send cleaner later:</p>

        <Form onSubmit={this.handleSubmit}>
          <Form.Item label={<span>customer name</span>}>
            {getFieldDecorator("customerName", {
              rules: [
                {
                  required: true,
                  message: "please type your name",
                  whitespace: true,
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="phone number">
            {getFieldDecorator("phone", {
              rules: [
                { required: true, message: "please type your phone number" },
                { validator: this.validateUSTelephone },
              ],
            })(<Input style={{ width: "100%" }} placeholder="xxx-xxx-xxxx" />)}
          </Form.Item>

          <Form.Item label="email">
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "keep typing: email address not yet valid",
                },
                {
                  required: true,
                  message: "please type your email!",
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="service address">
            {getFieldDecorator("serviceAddress", {
              rules: [
                {
                  required: true,
                  message: "please type your service address",
                },
              ],
            })(<TextArea style={{ width: "100%" }} />)}
          </Form.Item>

          <Form.Item label="service date">
            {getFieldDecorator("serviceDate", {
              rules: [
                { required: true, message: "please type your service date!" },
              ],
            })(
              <DatePicker
                style={{ width: "100%" }}
                format="MM/DD/YYYY"
                onBlur={() =>
                  this.props.form.isFieldTouched("serviceDate", true)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="service time">
            {getFieldDecorator("serviceTime", {
              rules: [
                { required: true, message: "please type your service time" },
              ],
            })(
              <TimePicker
                use12Hours
                minuteStep={15}
                style={{ width: "100%" }}
                inputReadOnly
                format="h:mm a"
              />
            )}
          </Form.Item>

          <Form.Item
            label="requested number of hours"
            extra="Example: typical routine cleaning for a 1 bed, 1 bath apt. may take 2-3 hours"
          >
            <Row gutter={8}>
              <Col span={12}>
                <Slider
                  min={0}
                  max={9}
                  step={0.5}
                  onChange={this.onChangeSlideValue}
                  value={
                    typeof slideInputValue === "number" ? slideInputValue : 0
                  }
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={0}
                  max={9}
                  step={0.5}
                  style={{ marginLeft: 16 }}
                  value={slideInputValue}
                  onChange={this.onChangeSlideValue}
                />
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label="coupon code"
            extra="Coupon code will be applied upon assignment of cleaner"
          >
            {getFieldDecorator("couponCode", {
              rules: [{ required: false, message: "please type coupon code" }],
            })(<Input style={{ width: "100%" }} />)}
          </Form.Item>

          <Form.Item label="price">
            <div style={{ whiteSpace: "pre-wrap" }}>
              ${this.state.price} + ${this.state.wfTaxAMTPercentage}%{" "}
              {this.state.wfTaxDescription}
            </div>
          </Form.Item>

          <Form.Item label="total price">
            <div>${this.state.totalPrice}</div>
          </Form.Item>

          <Form.Item label="service frequency">
            {getFieldDecorator("serviceFrequency", {
              initialValue: ["Once"],
              rules: [
                {
                  type: "array",
                  required: true,
                  message: "please select service frequency",
                },
              ],
            })(<Cascader options={frequency} />)}
          </Form.Item>

          <Form.Item
            label="comments/instructions"
            extra="if confirmed, you will be contacted for your payment information"
          >
            {getFieldDecorator("commentsInstructions", {
              rules: [
                {
                  required: false,
                  message: "please type comments/instructions",
                },
              ],
            })(<TextArea style={{ width: "100%" }} />)}
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginBottom: "50px" }}
            >
              <span className="formNumberHuoursSign">Submit</span>
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create({ name: "FormData" })(FormData);
