import React from "react";
import { Redirect } from "react-router-dom";

import MaidhawkAPI from "../api";
import Spinner from "./elements/Spinner";

export default class StripeExpress extends React.Component {
  static propTypes = {};
  static defaultProps = {};
  state = {
    redirectToNotFound: false,
  };

  componentDidMount() {
    var params = {};
    this.props.location.search
      .slice(1) // drop the '?
      .split("&")
      .forEach((item) => {
        const [key, value] = item.split("=");
        params[key] = value;
      });

    if (params.error) {
      this.setState({
        error: encodeURI(params.error_description),
      });
    } else if (!params.code) {
      this.setState({
        redirectToNotFound: true,
      });
    } else {
      MaidhawkAPI.call("PUT", `/v1/maid/${params.state}/stripe/account`, params)
        .then(() => this.setState({ status: "good" }))
        // the full error message was shown in an alert
        .catch((message) => this.setState({ error: "unsuccessful" }));
    }
  }

  render() {
    if (this.state.redirectToNotFound === true) {
      return <Redirect to={"/notfound"} />;
    }

    return (
      <div
        className="row d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: "rgb(224, 217, 217)",
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0px",
        }}
      >
        <div
          className="col-md-12"
          style={{
            width: "300px",
            maxWidth: "300px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="row" style={{ justifyContent: "center" }}>
            <img
              alt="maidhawk logo"
              src="resources/MaidHawkLogo.png"
              style={{ height: "100px", width: "200px" }}
            />
          </div>
          <div className="row" style={{ textAlign: "center" }}>
            {this.state.error !== undefined ? (
              <div className="col-md-12">
                <h1 style={{ color: "Red" }}>{this.state.error}</h1>
                <p>
                  please contact our system administrator at info@maidhawk.com
                </p>
              </div>
            ) : this.state.status === "good" ? (
              <div className="col-md-12">
                <h1>congratulations!</h1>
                <p>you have succesfully configured your bank account.</p>
                <p>you can begin using our app!</p>
              </div>
            ) : (
              <div className="col-md-12">
                <h1>please wait while we finish configuring your account.</h1>
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
