import React from "react";

export default class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div id="preFooter">
          <div
            className="row"
            style={{ backgroundColor: "rgba(26,26,26,.7)", color: "#fff" }}
          >
            <div
              className="col-12"
              data-layout-label="Pre-Footer Content"
              data-type="block-field"
            >
              <br />
            </div>
          </div>
        </div>
        <footer id="footer" role="contentinfo">
          <div
            className="row"
            style={{ textAlign: "center", minHeight: "150px" }}
          >
            <div
              className="col-12"
              style={{ color: "white" }}
              data-layout-label="Footer Content"
              data-type="block-field"
              data-updated-on="1389963872562"
              id="footerBlocks"
            >
              <div className="row">
                <div className="col col-12">
                  <div
                    className="sqs-block html-block sqs-block-html"
                    data-block-type="2"
                    id="block-713fe491a2c303b33937"
                  >
                    <div className="sqs-block-content">
                      {window.env.SOURCE_VERSION}
                      <br />
                      {this.props.children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
