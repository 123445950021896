import React from "react";
import * as d3 from "d3";

export default class BarChartGraph extends React.Component {
  options = {
    margin: 50,
  };
  mainDiv = null;
  svg = null;

  componentDidMount() {
    this.mainDiv = d3.select("#" + this.props.id);
    this.svg = this.mainDiv.append("svg");
    this.createDefinitions();

    this.componentDidUpdate();
  }

  componentDidUpdate() {
    this.drawGraph([this.props.data]);
  }

  createDefinitions() {
    const defs = this.svg.append("defs");
    const filter = defs
      .append("filter")
      .attr("id", "MyFilter")
      .attr("filterUnits", "userSpaceOnUse")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", "100%")
      .attr("height", "100%");
    filter
      .append("feGaussianBlur")
      .attr("in", "blur")
      .attr("stdDeviation", 1)
      .attr("result", "blur");
    filter
      .append("feOffset")
      .attr("in", "blur")
      .attr("dx", 1)
      .attr("dy", 1)
      .attr("result", "offsetBlur");
    filter
      .append("feSpecularLighting")
      .attr("in", "blur")
      .attr("surfaceScale", 5)
      .attr("specularConstant", 0.75)
      .attr("specularExponent", 20)
      .attr("lighting-color", "#bbbbbb")
      .attr("result", "specOut")
      .append("fePointLight")
      .attr("x", -5000)
      .attr("y", -10000)
      .attr("y", 20000);
    filter
      .append("feComposite")
      .attr("in", "specOut")
      .attr("in2", "SourceAlpha")
      .attr("operator", "in")
      .attr("result", "specOut");
    filter
      .append("feComposite")
      .attr("in", "SourceGraphic")
      .attr("in2", "specOut")
      .attr("operator", "arithmetic")
      .attr("k1", "0")
      .attr("k2", "1")
      .attr("k3", "1")
      .attr("k4", "0")
      .attr("result", "litPaint");
    const mergeFilter = filter.append("feMerge");
    mergeFilter.append("feMergeNode").attr("in", "offsetBlur");
    mergeFilter.append("feMergeNode").attr("in", "litPaint");
  }

  drawTitle() {
    const titleGroup = this.svg.append("g");
    const titleElement = titleGroup
      .append("text")
      .style("font-size", "0.9em")
      .attr("class", "advancedChartTitle")
      .text(this.props.title);
    const box = titleElement.node().getBBox();
    titleElement
      .attr("x", this.svg.node().clientWidth / 2 - box.width / 2)
      .attr("y", box.height * 1.5);
  }

  drawXLegend() {
    const titleGroup = this.svg.append("g");
    const titleElement = titleGroup
      .append("text")
      .attr("class", "advancedChartTitle")
      .text(this.props.xLegend);
    const box = titleElement.node().getBBox();
    titleElement
      .attr("x", this.svg.node().clientWidth / 2 - box.width / 2)
      .attr("y", this.svg.node().clientHeight - box.height * 1.5);
  }

  drawYLegend() {
    const titleGroup = this.svg.append("g");
    const titleElement = titleGroup
      .append("text")
      .attr("class", "advancedChartTitle")
      .text(this.props.yLegend)
      .style("transform", "rotate(-90deg)");
    const box = titleElement.node().getBBox();
    titleElement
      .attr("x", -(this.svg.node().clientHeight / 2 + box.width / 2))
      .attr("y", this.options.margin / 2 - box.height / 2);
  }

  drawGraph = (dataSets) => {
    const data = dataSets[0];

    this.svg.selectAll("g").remove();
    this.svg.selectAll("rect").remove();

    let width = this.mainDiv.node().clientWidth;
    this.svg.attr("width", width);

    let height = this.mainDiv.node().clientHeight;
    height = height < 300 ? 300 : height;
    height = height > 500 ? 500 : height;
    this.svg.attr("height", height);

    const borderGroup = this.svg
      .append("g")
      .attr(
        "transform",
        "translate(" +
          (this.options.margin - 10) +
          "," +
          (this.options.margin - 10) +
          ")"
      );

    const graphGroup = borderGroup
      .append("g")
      .attr("transform", "translate(10,10)");

    const div = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    width = width - 2 * this.options.margin;
    const xScale = d3.scaleTime().range([0, width]);

    height = height - 2 * this.options.margin;
    const yScale = d3.scaleLinear().range([height, 0]);

    const extent = d3.extent(data.map((d) => d.time));
    xScale.domain(extent);
    const xAxis = d3.axisBottom().scale(xScale);

    const yValues = data.map((y) => y.value);
    yScale.domain([0, d3.max(yValues) + 1]);
    const yAxis = d3.axisLeft().scale(yScale);

    const axisGroup = graphGroup.append("g");
    const xAxisGroup = axisGroup
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .attr("class", "x axis")
      .call(xAxis);

    const yAxisGroup = axisGroup
      .append("g")
      .attr("class", "y axis")
      .call(yAxis);

    const minorGridxAxisGroup = axisGroup
      .append("g")
      .attr("class", "minorGrid")
      .attr("transform", "translate(0," + height + ")")
      .call(
        d3
          .axisBottom()
          .scale(xScale)
          .tickSize(-height, 0, 0)
          .ticks(50)
          .tickFormat("")
      );

    // const minorGridyAxisGroup =
    axisGroup
      .append("g")
      .attr("class", "minorGrid")
      .call(
        d3
          .axisLeft()
          .scale(yScale)
          .tickSize(-width, 0, 0)
          .ticks(50)
          .tickFormat("")
      );

    const majorGridxAxisGroup = axisGroup
      .append("g")
      .attr("class", "majorGrid")
      .attr("transform", "translate(0," + height + ")")
      .call(
        d3
          .axisBottom()
          .scale(xScale)
          .tickSize(-height, 0, 0)
          .ticks(5)
          .tickFormat("")
      );

    // const majorGridyAxisGroup =
    axisGroup
      .append("g")
      .attr("class", "majorGrid")
      .call(
        d3
          .axisLeft()
          .scale(yScale)
          .tickSize(-width, 0, 0)
          .ticks(5)
          .tickFormat("")
      );

    borderGroup
      .append("clipPath")
      .attr("id", this.props.id + "clip")
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", width)
      .attr("height", height);

    this.drawTitle();
    this.drawXLegend();
    this.drawYLegend();

    // draw bar graph
    const barsGroup = graphGroup.append("g");
    barsGroup.attr("clip-path", "url(#" + this.props.id + "clip)");
    barsGroup
      .selectAll("bar")
      .data(data)
      .enter()
      .append("rect")
      .style("fill", "steelblue")
      .attr("x", function (d) {
        return xScale(d.time) - width / data.length / 2 + 1;
      })
      .attr("width", width / data.length - 1)
      .attr("y", function (d) {
        return yScale(d.value);
      })
      .attr("height", function (d) {
        return height - yScale(d.value);
      })
      .attr("filter", "url(#MyFilter)");

    // draw line graph
    const line = d3
      .line()
      .x(function (d) {
        return xScale(d.time);
      })
      .y(function (d) {
        return yScale(d.value);
      });
    const linePathGroup = graphGroup.append("g");
    linePathGroup.attr("clip-path", "url(#" + this.props.id + "clip)");
    const linePath = linePathGroup
      .append("path")
      .data([data])
      .attr("class", "advancedline")
      .attr("d", line);

    const zoomBehavior = d3.zoom();

    const eventsRect = this.svg
      .append("rect")
      .attr(
        "transform",
        "translate(" + this.options.margin + "," + this.options.margin + ")"
      )
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", width)
      .attr("height", height)
      .attr("fill", "rgba(0,0,0,0)")
      .attr("stroke", "none")
      .call(zoomBehavior.on("zoom", zoom));

    const eventsGroup = eventsRect.append("g");
    eventsGroup.attr("clip-path", "url(#clip)");
    eventsGroup
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => {
        return xScale(d.time);
      })
      .attr("cy", (d) => {
        return yScale(d.value);
      })
      .attr("r", 5)
      .attr("filter", "url(#MyFilter)")
      .style("fill", "rgba(0,0,0,0)")
      .style("stroke", "none")
      .on("mouseout", function (d) {
        div.transition().duration(500).style("opacity", 0);
      });

    // draw dots
    const dotsGroup = graphGroup.append("g");
    dotsGroup.attr("clip-path", "url(#" + this.props.id + "clip)");
    dotsGroup
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => {
        return xScale(d.time);
      })
      .attr("cy", (d) => {
        return yScale(d.value);
      })
      .attr("r", 5)
      .attr("filter", "url(#MyFilter)")
      .attr("class", "advancedCircle")
      .on("mouseover", function (d) {
        div.transition().duration(200).style("opacity", 0.9);
        div
          .html(d.time + "<br/>" + d.close)
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY - 28 + "px");
      })
      .on("mouseout", function (d) {
        div.transition().duration(500).style("opacity", 0);
      });

    function zoom() {
      xAxisGroup.call(xAxis);
      yAxisGroup.call(yAxis);
      minorGridxAxisGroup.call(
        d3
          .axisBottom()
          .scale(xScale)
          .tickSize(-height, 0, 0)
          .ticks(50)
          .tickFormat("")
      );
      majorGridxAxisGroup.call(
        d3
          .axisBottom()
          .scale(xScale)
          .tickSize(-height, 0, 0)
          .ticks(5)
          .tickFormat("")
      );

      barsGroup
        .selectAll("bar")
        .attr("x", function (d) {
          return xScale(d.time) - width / data.length / 2 + 1;
        })
        .attr("width", width / data.length - 1)
        .attr("y", function (d) {
          return yScale(d.value);
        })
        .attr("height", function (d) {
          return height - yScale(d.value);
        })
        .attr("filter", "url(#MyFilter)");

      linePath.attr("d", line);
      dotsGroup
        .selectAll("circle")
        .attr("cx", (d) => {
          return xScale(d.time);
        })
        .attr("cy", (d) => {
          return yScale(d.value);
        });
    }
  };

  render() {
    return (
      <div
        id={this.props.id}
        className="col-md-12"
        style={{ padding: "0px", width: "100%", height: "100%" }}
      />
    );
  }
}
