import React from "react";

import CleaningForm from "../elements/CleaningForm.js";

export default class request extends React.Component {
  state = {
    submitted: false,
  };

  componentDidUpdate() {
    document.getElementById("processSpinner").style.visibility = "hidden";
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          {!this.state.submitted ? (
            <CleaningForm
              onChangeValue={() => this.setState({ submitted: true })}
            />
          ) : (
            <p>
              Thank you for submitting your cleaning request. You will receive a
              confirmation of your date and time, or be contacted shortly with
              other available times. Any coupon discounts will also be applied
              at that time.
            </p>
          )}
        </div>
      </div>
    );
  }
}
