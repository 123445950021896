// monkey-patch the Intl global object
Intl.TelephoneFormat = (locale, opts) => ({
  format(value, returnIfInvalid = null) {
    if (opts.country && opts.country.toUpperCase() !== "US") {
      return value; // unsupported country specifically other than US
    }

    const phone = String(value)
      .replace(/[^0-9]/g, "")
      .match(/^\+?(\d*)?(\d{3})(\d{3})(\d{4})$/);
    if (!phone) {
      return returnIfInvalid; // invalid
    }

    if (phone[1]) {
      return `+${phone[1]}${phone[2]}${phone[3]}${phone[4]}`;
    }
    return `(${phone[2]})${phone[3]}-${phone[4]}`;
  },
});

Intl.PostalCode = (locale, opts) => ({
  format(value, returnIfInvalid = null) {
    if (opts.country && opts.country.toUpperCase() !== "US") {
      return value; // unsupported country specifically other than US
    }

    // pad with zero to length of 5 or 9
    value = String(value).replace(/[^0-9]/g, "");
    if (value.length < 5) value = value.padStart(5, "0");
    else if (value.length > 5 && value.length < 9)
      value = value.padStart(9, "0");

    const zip = value.match(/^(\d{5})(\d{4})?$/);
    if (!zip) {
      return returnIfInvalid; // invalid
    }

    if (zip[2] && zip[2].length) {
      return `${zip[1]}-${zip[2]}`;
    }
    return zip[1];
  },
});

Intl.USZip = (value) => {
  return Intl.PostalCode("en-US", { country: "US" }).format(
    String(value) || ""
  );
};
Intl.USTelephone = (value) => {
  return Intl.TelephoneFormat("en-US", { country: "US" }).format(
    String(value) || ""
  );
};
Intl.USMoney = (value) => {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(Number(value || 0));
};
Intl.Percent = (value) => {
  return Intl.NumberFormat("en-US", { style: "percent" }).format(
    Number(value || 0)
  );
};
Intl.Hours = (value) => {
  return `${Number(value || 0).toFixed(2)} hours`;
};
Intl.USAddress = (street, unit, city, state, zip) => {
  return [
    [[street, unit].filter(Boolean).join(" "), city, state]
      .filter(Boolean)
      .join(", "),
    zip,
  ]
    .filter(Boolean)
    .join("  ");
};

// a tagged template string
Intl.pricing = (_, beds, baths) => `${beds} beds/${baths} baths`;
