import React from "react";
import Moment from "react-moment";
import ReactTable from "react-table";
import "react-table/react-table.css";

import classnames from "classnames";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import DatePicker from "material-ui/DatePicker";
import Toggle from "material-ui/Toggle";

import MaidhawkAPI from "../../../api";
import { reactMomentFormatDate } from "../../../constants";

export default class Coupons extends React.Component {
  static propTypes = {};
  static defaultProps = {};
  state = {
    couponDialogOpen: false,
    coupons: [],
    coupon: {
      code: "",
    },
  };

  componentDidMount() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("GET", "/v1/coupons").then((coupons) => {
      this.setState({ coupons });
    });
  }

  addCoupon() {
    this.setState({ couponDialogOpen: true, coupon: { code: "" } });
  }

  editCoupon(coupon) {
    this.setState({ couponDialogOpen: true, coupon });
  }

  saveCoupon() {
    document.getElementById("processSpinner").style.visibility = "visible";

    let method = "POST",
      url = "/v1/coupons";
    if ("_id" in this.state.coupon) {
      method = "PUT";
      url = `/v1/coupon/${this.state.coupon._id}`;
    }

    MaidhawkAPI.call(method, url, this.state.coupon).then((coupon) => {
      // put new/updated coupon at top of the list
      const coupons = this.state.coupons.filter((c) => c._id !== coupon._id);
      coupons.splice(0, 0, coupon);

      this.setState({
        couponDialogOpen: false,
        coupons,
      });
    });
  }

  componentDidUpdate() {
    document.getElementById("processSpinner").style.visibility = "hidden";
  }

  render() {
    return (
      <div className={classnames("About", this.props.className)}>
        <div className="row" style={commonStyles.groupBoxContainer}>
          <div className="col-md-12" style={{ padding: "0" }}>
            <div className="row" style={commonStyles.groupBox}>
              <div className="col-md-12" style={{ padding: "0px" }}>
                <div className="row formPair">
                  <div className="offset-10 col-md-2">
                    <FlatButton
                      label="add coupon"
                      labelStyle={commonStyles.headerButtonLabel}
                      style={actionButtonStyle}
                      fullWidth={true}
                      onClick={() => this.addCoupon()}
                    />
                  </div>
                </div>
                <div className="row" style={{ padding: "0px 0px 30px 0px" }}>
                  <div className="col-md-12" style={{ padding: "0px" }}>
                    <ReactTable
                      className="-striped -highlight"
                      data={this.state.coupons}
                      defaultPageSize={10}
                      filterable
                      defaultFilterMethod={(filter, row) =>
                        String(row[filter.id])
                          .toLowerCase()
                          .includes(filter.value.toLowerCase())
                      }
                      columns={[
                        {
                          Header: "coupon code",
                          accessor: "code",
                        },
                        {
                          id: "discount",
                          Header: "discount",
                          style: { textAlign: "right" },
                          accessor: (row) =>
                            row.percentDiscount
                              ? Intl.Percent(row.percentDiscount / 100.0)
                              : Intl.USMoney(row.cashDiscount),
                        },
                        {
                          id: "activeOn",
                          Header: "active on",
                          style: { textAlign: "center" },
                          accessor: (row) => new Date(row.activeOn),
                          Cell: (cell) => (
                            <Moment
                              format={reactMomentFormatDate}
                              date={cell.value}
                            />
                          ),
                          filterable: false,
                        },
                        {
                          id: "expiresOn",
                          Header: "expires on",
                          style: { textAlign: "center" },
                          accessor: (row) => new Date(row.expiresOn),
                          Cell: (cell) => (
                            <Moment
                              format={reactMomentFormatDate}
                              date={cell.value}
                            />
                          ),
                          filterable: false,
                        },
                        {
                          id: "singleUse",
                          Header: "single use?",
                          style: { textAlign: "center" },
                          accessor: (row) => (
                            <Toggle
                              style={{ color: "black" }}
                              toggled={!!row.singleUse}
                            />
                          ),
                        },
                        {
                          id: "isGroupon",
                          Header: "isGroupon",
                          style: { textAlign: "center" },
                          accessor: (row) => (
                            <Toggle
                              style={{ color: "black" }}
                              toggled={!!row.isGroupon}
                            />
                          ),
                        },
                        {
                          Header: "number of uses",
                          accessor: "numberOfUsers",
                          style: { textAlign: "right" },
                        },
                        {
                          id: "status",
                          Header: "status",
                          style: { textAlign: "right" },
                          accessor: (row) =>
                            new Date(row.expiresOn) < new Date()
                              ? "expired"
                              : new Date() < new Date(row.activeOn)
                              ? "inactive"
                              : row.status
                              ? row.status.toLowerCase()
                              : "active",
                          filterMethod: (filter, row) =>
                            filter.value === "all" ||
                            row[filter.id] === filter.value.toLowerCase(),
                          Filter: ({ filter, onChange }) => (
                            <select
                              style={{ width: "100%" }}
                              value={filter ? filter.value : "all"}
                              onChange={(event) => onChange(event.target.value)}
                            >
                              <option value="all">show all</option>
                              <option value="INACTIVE">inactive</option>
                              <option value="ACTIVE">active</option>
                              <option value="USED">used</option>
                              <option value="EXPIRED">expired</option>
                            </select>
                          ),
                        },
                      ]}
                      getTrProps={(_, reactTableRow) => ({
                        onClick: () => this.editCoupon(reactTableRow.original),
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          contentStyle={commonStyles.dialogStyle}
          actionsContainerStyle={commonStyles.actionsContainerStyle}
          actions={[
            <div className="row">
              <div className="col-md-6">
                <FlatButton
                  label="save coupon"
                  labelStyle={commonStyles.headerButtonLabel}
                  style={actionButtonStyle}
                  fullWidth={true}
                  onClick={() => this.saveCoupon()}
                />
              </div>
              <div className="col-md-6">
                <FlatButton
                  label="cancel"
                  labelStyle={commonStyles.headerButtonLabel}
                  style={actionButtonStyle}
                  fullWidth={true}
                  onClick={() =>
                    this.setState({
                      couponDialogOpen: false,
                    })
                  }
                />
              </div>
            </div>,
          ]}
          autoScrollBodyContent={true}
          modal={true}
          open={this.state.couponDialogOpen}
        >
          <div className="row">
            <div className="col-md-12" style={{ padding: "0px" }}>
              <div
                className="row"
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                <div className="col-md-12">
                  <TextField
                    style={{ color: "black", width: "100%" }}
                    hintText="coupon code"
                    floatingLabelText="coupon code"
                    errorText={
                      this.state.coupon.code.length
                        ? undefined
                        : "this field is required"
                    }
                    value={this.state.coupon.code}
                    onChange={(e) =>
                      this.setState({
                        coupon: { ...this.state.coupon, code: e.target.value },
                      })
                    }
                  />
                </div>
              </div>
              <div
                className="row"
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                <div className="col-md-12">
                  <TextField
                    style={{ color: "black", width: "100%" }}
                    hintText="% discount"
                    floatingLabelText="% discount"
                    value={this.state.coupon.percentDiscount || ""}
                    onChange={(e) =>
                      this.setState({
                        coupon: {
                          ...this.state.coupon,
                          percentDiscount: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div
                className="row"
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                <div className="col-md-12">
                  <TextField
                    style={{ color: "black", width: "100%" }}
                    hintText="$ discount"
                    floatingLabelText="$ discount"
                    value={this.state.coupon.cashDiscount || ""}
                    onChange={(e) =>
                      this.setState({
                        coupon: {
                          ...this.state.coupon,
                          cashDiscount: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div
                className="row"
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                <div className="col-md-12">
                  <DatePicker
                    firstDayOfWeek={0}
                    hintText="active on"
                    floatingLabelText="active on"
                    formatDate={(date) => date.toLocaleDateString("en-US")}
                    value={
                      this.state.coupon.activeOn
                        ? new Date(this.state.coupon.activeOn)
                        : new Date()
                    }
                    onChange={(_, value) =>
                      this.setState({
                        coupon: { ...this.state.coupon, activeOn: value },
                      })
                    }
                  />
                </div>
              </div>
              <div
                className="row"
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                <div className="col-md-12">
                  <DatePicker
                    firstDayOfWeek={0}
                    hintText="expires on"
                    floatingLabelText="expires on"
                    formatDate={(date) => date.toLocaleDateString("en-US")}
                    value={
                      this.state.coupon.expiresOn
                        ? new Date(this.state.coupon.expiresOn)
                        : new Date()
                    }
                    onChange={(_, value) =>
                      this.setState({
                        coupon: { ...this.state.coupon, expiresOn: value },
                      })
                    }
                  />
                </div>
              </div>
              <div
                className="row"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "50%",
                }}
              >
                <div className="col-md-12">
                  <Toggle
                    labelStyle={{ color: "black" }}
                    style={toggleStyle}
                    defaultToggled={false}
                    label="single use"
                    toggled={this.state.coupon.singleUse}
                    onToggle={(e, value) => {
                      console.log(e);
                      console.log(value);
                      this.setState({
                        coupon: {
                          ...this.state.coupon,
                          singleUse: value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div
                className="row"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "50%",
                }}
              >
                <div className="col-md-12">
                  <Toggle
                    labelStyle={{ color: "black" }}
                    style={toggleStyle}
                    defaultToggled={false}
                    label="is groupon"
                    toggled={this.state.coupon.isGroupon}
                    onToggle={(_, value) =>
                      this.setState({
                        coupon: {
                          ...this.state.coupon,
                          isGroupon: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

const commonStyles = {
  actionsContainerStyle: {
    padding: "20px 20px",
  },
  groupBoxContainer: {
    padding: "20px 10px 0px 10px",
  },
  groupBox: {
    backgroundColor: "white",
    border: "1px solid gray",
    borderRadius: "10px",
    padding: "5px 20px 0px 20px",
    color: "gray",
  },
  headerButtonContainer: {
    padding: "0px",
  },
  headerButtonLabel: {
    width: "100%",
    margin: "0px",
    padding: "0px",
    textTransform: "lowercase",
    fontSize: "10pt",
    lineHeight: "30px",
  },
  headerButton: {
    verticalAlign: "middle",
    color: "black",
    backgroundColor: "white",
    border: "1px solid gray",
    width: "100%",
    margin: "0px",
    lineHeight: "30px",
    height: "32px",
  },
  uploadInput: {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100%",
    opacity: 0,
  },
  dialogStyle: {
    width: "auto",
    maxWidth: "450px",
    padding: "20px 20px 0px 20px",
    color: "black",
  },
  headerDialogStyle: {
    background: "lightgray",
    color: "black",
    padding: "10px",
    height: "100%",
    alignContent: "center",
    border: "1px solid gray",
  },
  tableStyle: {
    width: "100%",
  },
  tableHeaderStyle: {
    fontSize: "12pt",
  },
  buttonContainerStyle: {
    textAlign: "right",
    padding: "20px",
  },
};
const actionButtonStyle = {
  ...commonStyles.headerButton,
  width: "auto",
};
const toggleStyle = {
  color: "black",
  marginBottom: 16,
};
