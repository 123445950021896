import React from "react";
import { NavLink, withRouter } from "react-router-dom";

import RaisedButton from "material-ui/RaisedButton";

import AdminHeader from "./AdminHeader";
import MaidhawkAPI from "../../api";

const LogoutButton = withRouter(({ history }) => (
  <div className="row" style={{ width: "100px" }}>
    <div className="col">
      <RaisedButton
        label="Sign out"
        style={{ width: "100%", color: "rgb(106, 171, 221)" }}
        onClick={() => {
          MaidhawkAPI.signout().then(() => (window.location = "/"));
        }}
      />
    </div>
  </div>
));

export default class Header extends React.Component {
  state = {
    navbarStacked: false,
  };

  handleClick = () => {
    this.setState({
      navbarStacked: !this.state.navbarStacked,
    });
  };

  render() {
    const navStyle = {
      spread: {
        zIndex: "10",
        backgroundColor: "rgba(158, 158, 158, 0.74)",
        padding: "0px 10px",
      },
      stacked: {
        zIndex: "10",
        backgroundColor: "rgba(158, 158, 158)",
        textAlign: "center",
        padding: "0px 10px",
      },
    };
    const navItemListClass = {
      spread: "navbar-collapse collapse",
      stacked: "navbar-collapse collapse show",
    };
    const navbarFormClass = {
      spread: "form-inline my-2 my-lg-0 justify-content-end",
      stacked: "form-inline my-2 my-lg-0 justify-content-center",
    };
    const linkContainerStyle = {
      alignItems: "center",
      paddingLeft: "10px",
    };
    const linkStyle = {
      lineHeight: "36px",
      paddingTop: "12px",
    };
    const activeLinkStyle = {
      paddingTop: "8px",
      fontWeight: "bolder",
      color: "#70ADD9",
      fontSize: "1.5em",
    };

    return (
      <React.Fragment>
        <nav
          className="navbar navbar-expand-lg navbar-toggleable-sm navbar-light bg-faded"
          style={navStyle[this.state.navbarStacked ? "stacked" : "spread"]}
        >
          <a className="navbar-brand" style={{ padding: "0px" }} href="/">
            <img
              src="resources/MaidHawkLogo.png"
              style={{ height: "120px" }}
              className="img-thumbnail"
              alt="maidhawklogo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            style={{ backgroundColor: "white" }}
            onClick={this.handleClick}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            id="navbarNavAltMarkup"
            className={
              navItemListClass[this.state.navbarStacked ? "stacked" : "spread"]
            }
            aria-expanded="true"
          >
            <div className="col-md-12 no-gutters">
              <div className="row">
                <div className="col-md-12">
                  <form
                    id="navbarForm"
                    className={
                      navbarFormClass[
                        this.state.navbarStacked ? "stacked" : "spread"
                      ]
                    }
                  >
                    <ul className="navbar-nav" style={linkContainerStyle}>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          style={linkStyle}
                          activeStyle={activeLinkStyle}
                          exact
                          to="/"
                        >
                          home
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          style={linkStyle}
                          activeStyle={activeLinkStyle}
                          to="/maids"
                        >
                          service providers
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          style={linkStyle}
                          activeStyle={activeLinkStyle}
                          to="/about"
                        >
                          about
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          style={linkStyle}
                          activeStyle={activeLinkStyle}
                          to="/faqs"
                        >
                          faqs
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        {MaidhawkAPI.authenticated() && <LogoutButton />}
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {MaidhawkAPI.authenticated() && <AdminHeader />}
      </React.Fragment>
    );
  }
}
