import React from "react";
import Moment from "react-moment";
import Workbook from "../../elements/Workbook";
import ReactStars from "react-stars";
import ReactTable from "react-table";
import "react-table/react-table.css";

import classnames from "classnames";

import Dialog from "material-ui/Dialog";
import TextField from "material-ui/TextField";
import IconButton from "material-ui/IconButton";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import Toggle from "material-ui/Toggle";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";

import EditableLabel from "../../elements/EditableLabel";

import MaidhawkAPI from "../../../api";
import {
  requestStatusDescription,
  reactMomentFormatDateTime,
  orderFlowNextSteps,
} from "../../../constants";

export default class Orders extends React.Component {
  static propTypes = {};
  static defaultProps = {};
  state = {
    orderId: false,
    orders: [],
  };

  componentDidMount() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("GET", "/v1/requests").then((orders) =>
      this.setState({ orders, orderId: false })
    );
  }

  describeRequest(request) {
    return request.details
      .map((d) =>
        [
          `${d.bedrooms || 0} bed`,
          `${d.bathrooms || 0} bath`,
          Intl.Hours(d.maxServiceTime),
          Intl.USMoney(d.price),
        ].join(" / ")
      )
      .concat(
        request.extras.map((e) =>
          [e.description, Intl.Hours(e.maxTime), Intl.USMoney(e.amount)].join(
            " / "
          )
        )
      )
      .join("\n");
  }

  componentDidUpdate() {
    document.getElementById("processSpinner").style.visibility = "hidden";
  }

  render() {
    return (
      <div className={classnames("About", this.props.className)}>
        <div style={workbookContainerStyle}>
          <Workbook
            filename={"orders" + new Date().toString() + ".xlsx"}
            element={<RaisedButton label="export to excel" />}
          >
            <Workbook.Sheet data={this.state.orders} name="Sheet A">
              <Workbook.Column label="order number" value="serialId" />
              <Workbook.Column label="order date" value="dateCreated" />
              <Workbook.Column
                label="order amount"
                value={(row) => Intl.USMoney(row.amount)}
              />
              <Workbook.Column
                label="customer"
                value={(row) => row.createdBy.email}
              />
              <Workbook.Column
                label="maid"
                value={(row) =>
                  row.maidAssigned ? row.maidAssigned.email : null
                }
              />
              <Workbook.Column label="street" value="street" />
              <Workbook.Column label="unit" value="unit" />
              <Workbook.Column
                label="zip"
                value={(row) => Intl.USZip(row.zip)}
              />
              <Workbook.Column
                label="order details"
                value={(row) => this.describeRequest(row)}
              />
              <Workbook.Column label="coupon number" value="couponNumber" />
              <Workbook.Column label="status" value="status" />
            </Workbook.Sheet>
          </Workbook>
        </div>
        <ReactTable
          className="-striped -highlight"
          data={this.state.orders}
          defaultPageSize={10}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toLowerCase()
              .includes(filter.value.toLowerCase())
          }
          columns={[
            {
              Header: "order id",
              accessor: "id",
              show: false,
            },
            {
              Header: "order number",
              accessor: "serialId",
            },
            {
              id: "orderDate",
              Header: "order date",
              accessor: (row) => new Date(row.dateCreated),
              Cell: (cell) => (
                <Moment format={reactMomentFormatDateTime} date={cell.value} />
              ),
              filterable: false,
            },
            {
              id: "orderAmount",
              Header: "order amount",
              style: { textAlign: "right" },
              accessor: (row) => Intl.USMoney(row.amount),
            },
            {
              Header: "customer",
              accessor: "createdBy.email",
            },
            {
              Header: "service provider",
              accessor: "maidAssigned.email",
            },
            {
              Header: "street",
              accessor: "street",
            },
            {
              Header: "unit",
              accessor: "unit",
            },
            {
              id: "zip",
              Header: "zip",
              accessor: (row) => Intl.USZip(row.zip),
            },
            {
              id: "coupon",
              Header: "coupon code",
              accessor: (row) => (row.coupon ? row.coupon.code : ""),
            },
            {
              Header: "status",
              accessor: "status",
              filterMethod: (filter, row) =>
                filter.value.toLowerCase() === "all" ||
                (String(row[filter.id])
                  .toLowerCase()
                  .startsWith(filter.value.toLowerCase()) &&
                  String(row[filter.id])
                    .toLowerCase()
                    .endsWith(filter.value.toLowerCase())),
              Filter: ({ filter, onChange }) => (
                <select
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  onChange={(event) => onChange(event.target.value)}
                >
                  <option value="all">show all</option>
                  <option value="CREATED">created</option>
                  <option value="ASSIGNED">assigned</option>
                  <option value="ARRIVED">arrived</option>
                  <option value="UNPAID">unpaid</option>
                  <option value="COMPLETED">completed</option>
                  <option value="CANCELEDBYCUSTOMER">
                    {" canceled by customer "}
                  </option>
                  <option value="CANCELEDBYMAID">
                    {" canceled by service provider "}
                  </option>
                  <option value="CANCELEDBYADMIN">
                    {" canceled by admin "}
                  </option>
                  <option value="CANCELEDBYSYSTEM">
                    {" expired by system "}
                  </option>
                </select>
              ),
            },
          ]}
          getTrProps={(_, reactTableRow) => ({
            onClick: () =>
              this.setState({ orderId: reactTableRow.original.id }),
          })}
        />
        {this.state.orderId ? (
          <OrderDialog
            orderId={this.state.orderId}
            close={() => this.componentDidMount()}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

class OrderDialog extends React.Component {
  static propTypes = {};
  static defaultProps = {};
  state = {
    order: {
      createdBy: { orders: [] },
      maidAssigned: { cleanings: [] },
      details: [],
      extras: [],
    },
    refundDialogOpen: false,
    refundAmount: 0,
    extraChargeDialogOpen: false,
    extraChargeAmount: 0,
    reassignMenuOpen: false,
    reassignDialogOpen: false,
    maids: [],
    starsFilter: 0,
  };

  componentDidMount() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("GET", `/v1/request/${this.props.orderId}`)
      .then((order) =>
        Promise.all([
          MaidhawkAPI.call("GET", `/v1/request/${order.id}/audit`).then(
            (audit) => {
              order.audit = audit;
              order.acceptedDate = order.audit
                .filter((item) => item.newStatus === "ASSIGNED")
                .reduce((_, log) => log.eventDate, false);

              order.arrivedDate = order.audit
                .filter((item) => item.newStatus === "ARRIVED")
                .reduce((_, log) => log.eventDate, false);

              order.completedDate = order.audit
                .filter((item) => item.newStatus === "COMPLETED")
                .reduce((_, log) => log.eventDate, false);
            }
          ),
          MaidhawkAPI.call("GET", `/v1/customer/${order.createdBy._id}`).then(
            (customer) => {
              order.createdBy = customer;
            }
          ),
          order.maidAssigned &&
            MaidhawkAPI.call("GET", `/v1/maid/${order.maidAssigned._id}`).then(
              (maid) => {
                order.maidAssigned = maid;
              }
            ),
        ]).then(() => order)
      )
      .then((order) => this.setState({ order }));
  }

  updateOrder(address) {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("PUT", `/v1/request/${this.props.orderId}`, address).then(
      () => this.props.close()
    );
  }

  cancelOrder() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("PUT", `/v1/request/${this.props.orderId}/status`, {
      status: "CANCELEDBYADMIN",
    }).then(() => this.props.close());
  }

  finishOrder() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("PUT", `/v1/request/${this.props.orderId}/status`, {
      status: "COMPLETED",
    }).then(() => this.props.close());
  }

  processReassignAuto() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call(
      "POST",
      `/v1/request/${this.props.orderId}/maid/assign`,
      {}
    ).then(() => this.props.close());
  }

  viewReassignment() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("GET", "/v1/maids").then((maids) => {
      this.setState({
        reassignMenuOpen: false,
        reassignDialogOpen: true,
        maids,
      });
    });
  }

  processReassignment(maidId) {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("PUT", `/v1/request/${this.props.orderId}/maid`, {
      maidId,
    }).then(() => this.props.close());
  }

  processRefund() {
    // this does NOTHING to process a refund?!
    this.setState({ refundDialogOpen: false });
  }

  processExtraCharge() {
    document.getElementById("processSpinner").style.visibility = "visible";

    MaidhawkAPI.call("POST", `/v1/request/${this.props.orderId}/charges`, {
      amount: this.state.extraChargeAmount,
    }).then(() => this.setState({ extraChargeDialogOpen: false }));
  }

  componentDidUpdate() {
    document.getElementById("processSpinner").style.visibility = "hidden";
  }

  render() {
    let orderNextSteps = orderFlowNextSteps[this.state.order.status] || [];

    return (
      <React.Fragment>
        <Dialog
          contentStyle={orderDialogStyle}
          actionsContainerStyle={commonStyles.actionsContainerStyle}
          autoScrollBodyContent={true}
          actions={[
            <FlatButton
              label="Ok"
              labelStyle={commonStyles.headerButtonLabel}
              style={actionButtonStyle}
              onClick={() => this.props.close()}
            />,
          ]}
          modal={true}
          open={true}
        >
          <div>
            <div className="row" style={orderActionsStyle}>
              <div
                className="col-md-3 clearfix"
                style={{ alignSelf: "center" }}
              >
                <span className="blackTitle">
                  {" "}
                  Order {this.state.order.serialId}{" "}
                </span>
              </div>
              <div className="col" style={{ padding: "0px" }}>
                <FlatButton
                  label="Cancel Order"
                  labelPosition="before"
                  labelStyle={{
                    ...orderActionLabelStyle,
                    opacity: orderNextSteps.includes("CANCEL") ? 1 : 0.3,
                  }}
                  style={orderActionStyle}
                  fullWidth={true}
                  containerElement="label"
                  disabled={!orderNextSteps.includes("CANCEL")}
                  onClick={this.cancelOrder.bind(this)}
                />
              </div>
              <div className="col" style={{ padding: "0px" }}>
                <FlatButton
                  label="refund"
                  style={orderActionStyle}
                  labelStyle={{
                    ...orderActionLabelStyle,
                    opacity: orderNextSteps.includes("REFUND") ? 1 : 0.3,
                  }}
                  fullWidth={true}
                  containerElement="label"
                  disabled={!orderNextSteps.includes("REFUND")}
                  onClick={() =>
                    this.setState({ refundDialogOpen: true, refundAmount: 0 })
                  }
                />
              </div>
              <div className="col" style={{ padding: "0px" }}>
                <FlatButton
                  label="extra charge"
                  style={orderActionStyle}
                  labelStyle={{
                    ...orderActionLabelStyle,
                    opacity: orderNextSteps.includes("CHARGE") ? 1 : 0.3,
                  }}
                  fullWidth={true}
                  containerElement="label"
                  disabled={!orderNextSteps.includes("CHARGE")}
                  onClick={() =>
                    this.setState({
                      extraChargeDialogOpen: true,
                      extraChargeAmount: 0,
                    })
                  }
                />
              </div>
              <div className="col" style={{ padding: "0px" }}>
                <FlatButton
                  label="mark as finished"
                  style={orderActionStyle}
                  labelStyle={{
                    ...orderActionLabelStyle,
                    opacity: orderNextSteps.includes("COMPLETE") ? 1 : 0.3,
                  }}
                  fullWidth={true}
                  containerElement="label"
                  disabled={!orderNextSteps.includes("COMPLETE")}
                  onClick={this.finishOrder.bind(this)}
                />
              </div>
              <div className="col" style={{ padding: "0px" }}>
                <IconMenu
                  style={{ width: "100%" }}
                  anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                  targetOrigin={{ horizontal: "left", vertical: "top" }}
                  iconButtonElement={
                    <FlatButton
                      label="reassign service provider"
                      style={orderActionStyle}
                      labelStyle={{
                        ...orderActionLabelStyle,
                        opacity: orderNextSteps.includes("ASSIGN") ? 1 : 0.3,
                      }}
                      fullWidth={true}
                      containerElement="label"
                      disabled={!orderNextSteps.includes("ASSIGN")}
                      onClick={() => this.setState({ reassignMenuOpen: true })}
                    />
                  }
                  open={this.state.reassignMenuOpen}
                  onChange={(e, value) =>
                    parseInt(value, 10) === 1
                      ? this.processReassignAuto()
                      : this.viewReassignment()
                  }
                >
                  <MenuItem value="1" primaryText="auto re-assign" />
                  <MenuItem value="2" primaryText="manual re-assign" />
                </IconMenu>
              </div>
            </div>
            <div className="row" style={{ padding: "20px 10px 0px 10px" }}>
              <div className="col-md-6">
                <div className="row" style={detailsBoxStyle}>
                  <OrderDetails
                    data={this.state.order}
                    updateOrder={this.updateOrder.bind(this)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="row" style={detailsBoxStyle}>
                  <CustomerDetails data={this.state.order.createdBy} />
                </div>
                <div className="row" style={detailsBoxStyle}>
                  <MaidDetails data={this.state.order.maidAssigned} />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          contentStyle={reassignDialogStyle}
          actionsContainerStyle={commonStyles.actionsContainerStyle}
          autoScrollBodyContent={true}
          actions={[
            <FlatButton
              label="Cancel"
              labelStyle={commonStyles.headerButtonLabel}
              style={actionButtonStyle}
              onClick={() => this.setState({ reassignDialogOpen: false })}
            />,
          ]}
          repositionOnUpdate={false}
          modal={true}
          open={this.state.reassignDialogOpen}
        >
          <div className="row" style={{ padding: "0px 15px" }}>
            <div className="col-md-12 clearfix" style={{ alignSelf: "center" }}>
              <span className="blackSubTitle" style={{ color: "gray" }}>
                re-assign service provider
              </span>
            </div>
          </div>
          <ReactTable
            className="-striped -highlight"
            data={this.state.maids}
            pageSize={10}
            defaultPageSize={10}
            showPageSizeOptions={false}
            autoScrollBodyContent={true}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            columns={[
              {
                Header: "service provider name",
                accessor: (row) => `${row.name} ${row.lastName}`,
              },
              {
                id: "mobileNumber",
                Header: "mobile",
                accessor: (row) => Intl.USTelephone(row.mobileNumber),
              },
              {
                id: "isOnline",
                Header: "online status",
                accessor: (row) => (
                  <Toggle style={{ color: "black" }} toggled={row.isOnline} />
                ),
              },
              {
                id: "rating",
                Header: "rating",
                accessor: (row) => (
                  <ReactStars
                    className="col-9 centeredStars"
                    count={5}
                    value={parseInt(row.rating, 10)}
                    edit={false}
                    size={18}
                    color2={"#ffd700"}
                  />
                ),
                filterMethod: (filter, row) =>
                  filter.value === 0 || row[filter.id] === filter.value,
                Filter: ({ onChange }) => (
                  <div className="row">
                    <div className="col-9">
                      <ReactStars
                        className="centeredStarsFilter"
                        half={false}
                        count={5}
                        size={18}
                        value={this.state.starsFilter}
                        onChange={(value) => {
                          this.setState({
                            starsFilter: value,
                          });
                          onChange(value);
                        }}
                        color2={"#ffd700"}
                      />
                    </div>
                    <div
                      className="col-2"
                      style={{ padding: "0px", margin: "0px" }}
                    >
                      <IconButton
                        onClick={() => {
                          this.setState({
                            starsFilter: 0,
                          });
                          onChange(0);
                        }}
                        style={{ padding: "0px", margin: "0px" }}
                      >
                        <DeleteIcon color={"#d7d7d7"} />
                      </IconButton>
                    </div>
                  </div>
                ),
              },
            ]}
            getTrProps={(_, reactTableRow) => ({
              onClick: () =>
                this.processReassignment(reactTableRow.original.id),
            })}
          />
        </Dialog>
        <Dialog
          contentStyle={smallDialogStyle}
          actionsContainerStyle={commonStyles.actionsContainerStyle}
          actions={[
            <FlatButton
              label="refund"
              labelStyle={commonStyles.headerButtonLabel}
              style={{
                ...actionButtonStyle,
                opacity: 0.3,
              }}
              disabled
              onClick={() => this.processRefund()}
            />,
            <FlatButton
              label="Cancel"
              labelStyle={commonStyles.headerButtonLabel}
              style={actionButtonStyle}
              onClick={() => this.setState({ refundDialogOpen: false })}
            />,
          ]}
          repositionOnUpdate={false}
          width="300px"
          modal={true}
          open={this.state.refundDialogOpen}
        >
          <div className="row" style={{ padding: "20px 15px" }}>
            <div className="col-md-12">
              <div className="row  formPair">
                <div className="col-md-12">
                  <TextField
                    floatingLabelStyle={textFieldFloatingLabelStyle}
                    style={textFieldStyle}
                    fullWidth={true}
                    type="readonly"
                    floatingLabelText="original order amount"
                    value={this.state.order.amount}
                  />
                </div>
              </div>
              <div className="row  formPair">
                <div className="col-md-12">
                  <TextField
                    floatingLabelStyle={textFieldFloatingLabelStyle}
                    style={textFieldStyle}
                    fullWidth={true}
                    floatingLabelFixed={true}
                    floatingLabelText="refund amount"
                    value={this.state.refundAmount}
                    onChange={(e) =>
                      this.setState({ refundAmount: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          contentStyle={smallDialogStyle}
          actionsContainerStyle={commonStyles.actionsContainerStyle}
          actions={[
            <FlatButton
              label="charge"
              labelStyle={commonStyles.headerButtonLabel}
              style={actionButtonStyle}
              onClick={() => this.processExtraCharge()}
            />,
            <FlatButton
              label="Cancel"
              labelStyle={commonStyles.headerButtonLabel}
              style={actionButtonStyle}
              onClick={() => this.setState({ extraChargeDialogOpen: false })}
            />,
          ]}
          repositionOnUpdate={false}
          width="300px"
          modal={true}
          open={this.state.extraChargeDialogOpen}
        >
          <div className="row" style={{ padding: "20px 15px" }}>
            <div className="col-md-12">
              <div className="row  formPair">
                <div className="col-md-12">
                  <TextField
                    floatingLabelStyle={textFieldFloatingLabelStyle}
                    style={textFieldStyle}
                    fullWidth={true}
                    floatingLabelFixed={true}
                    floatingLabelText="extra charge amount"
                    value={this.state.extraChargeAmount}
                    onChange={(e) =>
                      this.setState({ extraChargeAmount: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

class OrderDetails extends React.Component {
  componentWillUnmount() {
    // inspect the EditableLabel elements, see if changes must be saved
    if (
      this.state instanceof Object &&
      (("street" in this.state &&
        this.state.street !== this.props.data.street) ||
        ("unit" in this.state && this.state.unit !== this.props.data.unit) ||
        ("zip" in this.state && this.state.zip !== this.props.data.zip))
    ) {
      this.props.updateOrder(this.state);
    }
  }

  render() {
    const order = this.props.data || {};

    return (
      <div className="col-md-12">
        <div className="row formPair">
          <h3 className="blackTitle">order</h3>
        </div>
        <div className="row formPair">
          <div className="col-md-6 ">
            <span className="formLabel">street</span>
          </div>
          <div className="col-md-6">
            <EditableLabel
              id="street"
              tabIndex={100}
              initialValue={order.street}
              labelClass="editableLabel"
              inputClass="editableInputLabel"
              save={(street) => this.setState({ street })}
            />
          </div>
        </div>
        <div className="row formPair">
          <div className="col-md-6 ">
            <span className="formLabel">unit</span>
          </div>
          <div className="col-md-6">
            <EditableLabel
              id="unit"
              tabIndex={101}
              initialValue={order.unit}
              labelClass="editableLabel"
              inputClass="editableInputLabel"
              save={(unit) => this.setState({ unit })}
            />
          </div>
        </div>
        <div className="row formPair">
          <div className="col-md-6 ">
            <span className="formLabel">zip</span>
          </div>
          <div className="col-md-6">
            <EditableLabel
              id="zip"
              tabIndex={102}
              initialValue={Intl.USZip(order.zip)}
              labelClass="editableLabel"
              inputClass="editableInputLabel"
              save={(zip) => this.setState({ zip })}
            />
          </div>
        </div>
        <div className="row formPair">
          <div className="col-md-6">
            <span className="formLabel">Order details</span>
          </div>
          <div className="col-md-6">
            <span
              dangerouslySetInnerHTML={{
                __html: order.details
                  .map((d) => {
                    var bedrooms = d.bedrooms === null ? 0 : d.bedrooms;
                    var bathrooms = d.bathrooms === null ? 0 : d.bathrooms;
                    return `bedrooms: ${bedrooms}\nbathrooms: ${bathrooms}`;
                  })
                  .join("<br/>"),
              }}
            />
          </div>
        </div>
        <div className="row formPair">
          <div className="col-md-6">
            <span className="formLabel">Extras</span>
          </div>
          <div className="col-md-6">
            <span
              dangerouslySetInnerHTML={{
                __html:
                  order.extras.map((e) => e.description).join("<br/>") ||
                  "none",
              }}
            />
          </div>
        </div>
        <div className="row formPair">
          <div className="col-md-6">
            <span className="formLabel">Amount</span>
          </div>
          <div className="col-md-6">
            <span>{Intl.USMoney(order.amount)}</span>
          </div>
        </div>
        <div className="row formPair">
          <div className="col-md-6">
            <span className="formLabel">Discount</span>
          </div>
          <div className="col-md-6">
            <span> {Intl.USMoney(order.discountAmount)} </span>
            {order.coupon instanceof Object && order.coupon.code && (
              <span>({order.coupon.code})</span>
            )}
          </div>
        </div>
        <div className="row formPair">
          <div className="col-md-6">
            <span className="formLabel">Maid amount</span>
          </div>
          <div className="col-md-6">
            <span>{Intl.USMoney(order.maidAmount)}</span>
          </div>
        </div>
        <div className="row formPair">
          <div className="col-md-6">
            <span className="formLabel">Requested</span>
          </div>
          <div className="col-md-6">
            <Moment
              format={reactMomentFormatDateTime}
              date={order.dateCreated}
            />
          </div>
        </div>
        <div className="row formPair">
          <div className="col-md-6">
            <span className="formLabel">Accepted</span>
          </div>
          <div className="col-md-6">
            <span>
              {order.acceptedDate ? (
                <Moment
                  format={reactMomentFormatDateTime}
                  date={order.acceptedDate}
                />
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
        <div className="row formPair">
          <div className="col-md-6">
            <span className="formLabel">Arrived</span>
          </div>
          <div className="col-md-6">
            <span>
              {order.arrivedDate ? (
                <Moment
                  format={reactMomentFormatDateTime}
                  date={order.arrivedDate}
                />
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
        <div className="row formPair">
          <div className="col-md-6">
            <span className="formLabel">Finished</span>
          </div>
          <div className="col-md-6">
            <span>
              {order.completedDate ? (
                <Moment
                  format={reactMomentFormatDateTime}
                  date={order.completedDate}
                />
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
        <div className="row formPair">
          <div className="col-md-6">
            <span className="formLabel">Status</span>
          </div>
          <div className="col-md-6">
            <span className="formLabel">
              {requestStatusDescription[order.status]}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

class CustomerDetails extends React.Component {
  render() {
    const customer = this.props.data || {};
    return (
      <div className="col-md-12">
        <div className="row">
          <span className="blackTitle">customer</span>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="formLabel">Name</span>
          </div>
          <div className="col-md-6">
            <span>
              {customer.name} {customer.lastName}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="formLabel">Email</span>
          </div>
          <div className="col-md-6">
            <span>{customer.email}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="formLabel">Cell Phone</span>
          </div>
          <div className="col-md-6">
            <span>{Intl.USTelephone(customer.mobileNumber)}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="formLabel">Rating</span>
          </div>
          <div className="col-md-6">
            <span>
              {customer.averageMaidRating} (average of{" "}
              {customer.countMaidRatings} ratings)
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="formLabel">Past Requests</span>
          </div>
          <div className="col-md-6">
            <span>{customer.orders.length}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="formLabel">Status</span>
          </div>
          <div className="col-md-6">
            <span>{customer.status === "INACTIVE" ? "blocked" : "active"}</span>
          </div>
        </div>
      </div>
    );
  }
}

class MaidDetails extends React.Component {
  render() {
    if (!this.props.data) {
      return (
        <div className="col-md-12">
          <div className="row">
            <span className="blackTitle">service provider unassigned</span>
          </div>
        </div>
      );
    }

    const maid = this.props.data || {};
    return (
      <div className="col-md-12">
        <div className="row">
          <span className="blackTitle">service provider</span>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="formLabel">Name</span>
          </div>
          <div className="col-md-6">
            <span>
              {maid.name} {maid.lastName}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="formLabel">Email</span>
          </div>
          <div className="col-md-6">
            <span>{maid.email}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="formLabel">Cell Phone</span>
          </div>
          <div className="col-md-6">
            <span>{Intl.USTelephone(maid.mobileNumber)}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="formLabel">Rating</span>
          </div>
          <div className="col-md-6">
            <span>
              {maid.averageCustomerRating} (average of{" "}
              {maid.countCustomerRatings} customer ratings)
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="formLabel">Past Cleanings</span>
          </div>
          <div className="col-md-6">
            <span>{maid.cleanings.length}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <span className="formLabel">Status</span>
          </div>
          <div className="col-md-6">
            <span>
              {maid.status} {maid.isOnline ? "Online" : "Offline"}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const commonStyles = {
  actionsContainerStyle: {
    padding: "10px 35px 20px 35px",
  },
  groupBoxContainer: {
    padding: "20px 10px 0px 10px",
  },
  groupBox: {
    backgroundColor: "white",
    border: "1px solid gray",
    borderRadius: "10px",
    padding: "5px 20px 0px 20px",
    color: "gray",
  },
  headerButtonContainer: {
    padding: "0px",
  },
  headerButtonLabel: {
    width: "100%",
    margin: "0px",
    padding: "0px",
    textTransform: "lowercase",
    fontSize: "10pt",
    lineHeight: "30px",
  },
  headerButton: {
    verticalAlign: "middle",
    color: "black",
    backgroundColor: "white",
    border: "1px solid gray",
    width: "100%",
    margin: "0px",
    lineHeight: "30px",
    height: "32px",
  },
  uploadInput: {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100%",
    opacity: 0,
  },
  dialogStyle: {
    width: "85%",
    maxWidth: "none",
    padding: "0px",
  },
  subDialogStyle: {
    width: "65%",
    maxWidth: "none",
    padding: "0px",
    color: "black",
    marginTop: "-10%",
  },
  headerDialogStyle: {
    background: "lightgray",
    color: "black",
    padding: "10px",
    height: "100%",
    alignContent: "center",
    border: "1px solid gray",
  },
  tableStyle: {
    width: "100%",
  },
  tableHeaderStyle: {
    fontSize: "12pt",
  },
};
const workbookContainerStyle = {
  textAlign: "right",
  padding: "20px",
};
const orderDialogStyle = {
  width: "80%",
  maxWidth: "none",
  padding: "0px",
};
const reassignDialogStyle = {
  ...commonStyles.groupBox,
  marginTop: "0%",
};
const smallDialogStyle = {
  ...commonStyles.groupBox,
  width: "550px",
};
const actionButtonStyle = {
  ...commonStyles.headerButton,
  width: "auto",
  marginLeft: "10px",
};
const textFieldFloatingLabelStyle = {
  color: "gray",
  fontSize: "16pt !important",
};
const textFieldStyle = {
  backgroundColor: "white",
};

var orderActionsStyle = {
  background: "lightgray",
  color: "black",
  padding: "10px",
  border: "1px solid gray",
  height: "100%",
  alignContent: "center",
};
var detailsBoxStyle = {
  backgroundColor: "white",
  color: "gray",
  padding: "5px 20px 5px 20px",
  border: "1px solid gray",
  borderRadius: "10px",
  marginBottom: "10px",
};

const orderActionLabelStyle = {
  width: "100%",
  margin: "0px",
  padding: "0px",
  textTransform: "lowercase",
  fontSize: "10pt",
  lineHeight: "30px",
};
const orderActionStyle = {
  verticalAlign: "middle",
  color: "black",
  backgroundColor: "white",
  border: "1px solid gray",
  width: "100%",
  margin: "0px",
  lineHeight: "30px",
  height: "32px",
};
